<template>
  <div>
    <el-collapse v-model="activeItem">
      <div v-for="deck,index in decks" :key="deck.id">
        <div class="deck-item position-relative">
          <el-collapse-item v-if="deck.decks" :name="deck.id">
            <div :style="'width:'+width+'px'" slot="title">
              <div @click.stop="onDeckClick(deck)" :title="index.length>10?index:''" class="text-ellipsis-1 title"
                   :class="level>1?'fs-12':'fs-14'">
                <span :class="{'c-app-green text-strong':activedid == deck.id}">{{index}}</span>
              </div>
            </div>
            <div class="mg-l-20" v-if="deck.decks">
              <deck-item :activedid="activedid" v-on:on-options-click="onInnerOptionsClick"
                         v-on:on-deck-click="onInnerDeckClick" :open="open"
                         :decks="deck.decks" :level="level + 1">
              </deck-item>
            </div>
          </el-collapse-item>
          <div style="cursor: pointer" v-else>
            <div :style="'width:'+width+'px'" class="h-48 lh-48">
              <div @click.stop="onDeckClick(deck)" class="text-ellipsis-1 title" :class="level > 1?'fs-12':'fs-14'">
                <span :class="{'c-app-green text-strong':activedid == deck.id}">{{index}}</span>
              </div>
            </div>
          </div>
          <div class="edit position-absolute t-0 r-20">
            <el-dropdown :show-timeout="0" class="pd-t-10">
              <div class="display-flex pd-12">
                <div class="h-3 w-3 bdr-circular bg-app-green mg-r-2"></div>
                <div class="h-3 w-3 bdr-circular bg-app-green mg-r-2"></div>
                <div class="h-3 w-3 bdr-circular bg-app-green mg-r-2"></div>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="onOptionsClick(deck,'addDeck')">创建记忆库</el-dropdown-item>
                <el-dropdown-item @click.native="onOptionsClick(deck,'addCard')">添加卡牌</el-dropdown-item>
                <el-dropdown-item @click.native="onOptionsClick(deck,'editDeck')">编辑</el-dropdown-item>
                <el-dropdown-item @click.native="onOptionsClick(deck,'deleteDeck')">删除</el-dropdown-item>
                <el-dropdown-item @click.native="onOptionsClick(deck,'exportDeck')">导出</el-dropdown-item>
                <el-dropdown-item @click.native="onOptionsClick(deck,'moveDeck')">移动</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="count position-absolute t-14 r-26 c-b fs-10 pd-t-2">
            <div>{{deck.totalCard}}</div>
          </div>
        </div>
      </div>
    </el-collapse>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        width: 230,
        activeItem: []
      }
    },
    props: {
      decks: {
        type: Object,
        default: null
      },
      level: {
        type: Number,
        default: 0
      },
      activedid: {
        type: String,
        default: ''
      },
      open: {
        type: Boolean,
        default: true
      }
    },
    name: "DeckItem",
    methods: {
      onItemClick() {
        console.log('>>>>>');
      },
      onOptionsClick(deck, type) {
        this.$emit('on-options-click', {deck, type})
      },
      onInnerOptionsClick(event) {
        this.$emit('on-options-click', event)
      },
      onDeckClick(deck) {
        this.$emit('on-deck-click', deck)
      },
      onInnerDeckClick(deck) {
        this.$emit('on-deck-click', deck)
      },
    },
    mounted() {
      this.width = (this.width - this.level * 22);
      if (this.open) {
        this.activeItem = [];
        if (this.decks && this.level < 2) {
          for (let k in this.decks) {
            this.activeItem.push(this.decks[k].id);
          }
        }
      }
    }
  }
</script>

<style scoped>
  .deck-item .edit {
    opacity: 0;
  }
  .deck-item .count {
    opacity: 1;
  }
  .deck-item:hover > .edit {
    opacity: 1;
  }
  .deck-item:hover > .count {
    /* display: none; */
    visibility: hidden;
  }

  .deck-item:hover {
    color: #28BEA0;
  }

</style>
