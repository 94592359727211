<template>
  <div class="h-fill bg-f3f">
    <!-- 主页header -->
    <Header></Header>
    <!-- 相似内容  学习助手闪卡笔记区域 -->
    <common-item :list="indexDataTopList"></common-item>
    <!-- 中间学习方案区域 -->
    <study-program></study-program>
    <!-- 相似内容  没有盲点和冲刺高分区域 -->
    <common-item :list="indexDataBottomList"></common-item>
    <!-- 版本下载区域 -->
    <down-load-version></down-load-version>
    <!-- 首页底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
  import Header from '../components/index/Header.vue'
  import Footer from '../components/index/Footer.vue'
  import DownLoadVersion from '../components/index/DownLoadVersion.vue'
  import StudyProgram from '../components/index/StudyProgram.vue'
  import CommonItem from '../components/index/CommonItem.vue'

  export default {
    name: "Index",
    components: {
      Header,
      Footer,
      DownLoadVersion,
      StudyProgram,
      CommonItem
    },
    data() {
      return {
        indexDataBottomList: [
          {
            title: "独创主动练习法，让学习没有盲点",
            desc: "复习算法就是不用你考虑，让机器算法智能给你安排知识点的下次复习时间，这是极其省心的。而主动练习，是可以通过强大的搜索和学习行为筛选器，主动找出你想复习知识点进行巩固，实现人工与算法的完美结合",
            imgUrl: "/static/image/banner5.png",
            id: 1
          },
          {
            title: "海量优质学习资源，助你高效冲刺高分",
            desc: "近千万张闪卡，覆盖考研、考证、大学、高中、初中、英语、日语等各学科的知识点，为你节省大量制作知识闪卡的时间。",
            imgUrl: "/static/image/banner6.png",
            id: 2
          },
        ],
        indexDataTopList: [
          {
            title: "无学科之分，万能的学习助手",
            desc: "不管你想学英语、日语、德语……还是法律、数学、语文、医学…我都能完美胜任，因为我是如纸笔一样的基础学习工具和一套领先于时代的学习方法的结合体，值得你终身使用的",
            imgUrl: "/static/image/banner2.png",
            id: 1
          },
          {
            title: "科学的闪卡笔记，学习专家也在推荐",
            desc: "学习专家也推荐的学习方式，用闪卡笔记让大脑自动实现检索式学习，把前沿的科学学习方法应用在日常学习之中",
            imgUrl: "/static/image/banner3.png",
            id: 2
          }
        ]
      }
    },
    methods: {
      isMobile() {
        return /windows phone|iphone|ipad|android/ig.test(window.navigator.userAgent) ? 1 : 0
      }
    },
    mounted() {
      let res = this.isMobile()
      if (res === 1) {
        // 移动端
        this.$router.push({path:'/h5'})
      }
      this.$parent.hideNavigateBar();
    }
  }
</script>

<style scoped>

</style>
