<template>
  <el-dialog
      :visible.sync="isShowExportCard" width="30%" title="导出卡牌"
      :before-close="exportClose"
      :destroy-on-close="true"
      :close-on-click-modal="false">
    <div v-if="boforeExport">
      <div class="pd-b-20">
        将 "{{ exportDeck.name }}" 导出为 akpg 文件？
      </div>
      <div>
        <el-checkbox v-model="containLog" true-label="1" false-label="0">包括学习进度</el-checkbox>
        <el-checkbox v-model="containMedia" true-label="1" false-label="0">包括媒体文件</el-checkbox>
      </div>
    </div>
    <div v-else>
      <div class="myLoading" v-if="exportLoading">
        <div class="el-icon-loading my-loading-icon"></div>
        <div class="text">正在导出...</div>
        <div class="cancelBtn justify-content-center" @click="cancelDownloadCard">
          <el-button>取消</el-button>
        </div>
      </div>
      <div class="cancelBtn justify-content-center" v-if="isReExport">
        <el-button @click="reExport">重新导出</el-button>
      </div>
      <div v-if="successExport" class="continueBtn myLoading">
        <div class="mg-b-10 fs-20">导出成功</div>
        <el-button @click="downloadCard">立即下载</el-button>
      </div>
    </div>
    <span slot="footer" class="dialog-footer" v-if="boforeExport">
          <el-button @click="canel">取 消</el-button>
          <el-button type="primary" @click="confirmExport">确 定</el-button>
        </span>
  </el-dialog>
</template>

<script>
import axios from 'axios'
import request from '../libs/api.request'

let cancel;
const CancelToken = axios.CancelToken

export default {
  props: {
    isExportCard: {
      type: Boolean,
      default: false
    },
    exportDeck: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      isShowExportCard: this.isExportCard,
      exportLoading: false,
      successExport: false,
      isReExport: false,
      boforeExport: true,
      containLog: '0',
      containMedia: '1',
      downloadUrl: ''// 导出的下载链接
    }
  },
  methods: {
    confirmExport() {
      let key = 'exportCard';
      let useCount = localStorage.getItem(key) || 0;
      useCount++;
      localStorage.setItem(key, useCount);
      this.onExportCard(this.exportDeck)
    },
    canel() {
      this.successExport = false
      this.$emit('closeExportCard')
      this.isShowExportCard = false
    },
    exportClose(done) {
      this.successExport = false
      this.$emit('closeExportCard')
      done()
    },
    onExportCard(deck) {
      this.exportLoading = true
      this.isReExport = false
      this.boforeExport = false
      request.request({
        url: '/space.deck/export',
        params: {
          did: deck.id,
          containLog: this.containLog,
          containMedia: this.containMedia,
        },
        cancelToken: new CancelToken(function (c) {
          cancel = c
        })

      }).then(res => {
        this.exportLoading = false
        this.successExport = true
        this.downloadUrl = res.downloadUrl
      }).catch(err => {
        this.exportLoading = false;
        this.$message.error('导出失败');
      })
    },
    downloadCard() {
      location.href = this.downloadUrl
    },
    cancelDownloadCard() {
      cancel()
      this.$message({
        type: 'warning',
        message: '导出已被取消'
      })
      this.exportLoading = false
      this.isReExport = true
    },
    reExport() {
      this.onExportCard(this.exportDeck)
      this.isReExport = false
    },
  },
  mounted() {
    console.log(this.exportDeck);
  }
}
</script>

<style scoped>
.cancelBtn >>> .el-button {
  background-color: #28BEA0;
  color: #fff;
  width: 200px;
  border-radius: 10px;
  margin: 20px 0;
}

.continueBtn >>> .el-button {
  background-color: #ff9041;
  color: #fff;
  width: 200px;
  border-radius: 10px;
  margin: 20px 0;
}

.myLoading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.myLoading .text {
  margin-top: 20px;
  font-size: 20px;
  color: #28BEA0;
}

.my-loading-icon {
  font-size: 80px;
  color: #28BEA0;
}
</style>