<template>
  <div>
    <el-collapse v-model="activeItem">
      <!--<div class="position-relative">-->
      <!--<div @click="onItemClick(null)" style="cursor: pointer">-->
      <!--<div class="h-48 lh-48">-->
      <!--<div class="deck-name" :class="level == 0?'fs-18':'fs-16'">所有</div>-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->
      <div v-for="deck in decks" class="position-relative" :key="deck.id">
        <el-collapse-item v-if="deck.decks" :name="deck.id">
          <div @click.stop="onItemClick(deck)" slot="title" :style="'width:'+width+'px'">
            <div class="deck-name text-ellipsis-1" :class="level == 0?'fs-16':'fs-14'">{{deck.simpleName}}</div>
          </div>
          <div class="mg-l-20" v-if="deck.decks">
            <select-deck v-on:on-item-select="onItemClick" :decks="deck.decks"
                         :level="level + 1" :open="open"></select-deck>
          </div>
        </el-collapse-item>
        <div @click="onItemClick(deck)" style="cursor: pointer" v-else>
          <div class="h-48 lh-48">
            <div class="deck-name text-ellipsis-1" :class="level == 0?'fs-16':'fs-14'">{{deck.simpleName}}</div>
          </div>
        </div>
      </div>
    </el-collapse>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        width: 330,
        showEditIndex: -1,
        activeItem: []
      }
    },
    props: {
      decks: {
        type: Object,
        default: () => {}
      },
      level: {
        type: Number,
        default: 0
      },
      open: {
        type: Boolean,
        default: false
      }
    },
    name: "SelectDeck",
    methods: {
      onItemClick(deck) {
        this.$emit('on-item-select', deck)
      },
      onMouseOver(index) {
        this.showEditIndex = index;
      },
      onMouseOut(index) {
        if (this.showEditIndex == index) {
          this.showEditIndex = -1;
        }
      }
    },
    mounted() {
      this.width = (this.width - this.level * 20);
      if (this.open) {
        this.activeItem = [];
        if (this.level < 1) {
          for (let k in this.decks) {
            this.activeItem.push(this.decks[k].id);
          }
        }
      }
    }
  }
</script>

<style scoped>
  .deck-name:hover {
    color: #26b38c;
  }
</style>
