import axios from 'axios'
import VueCookies from 'vue-cookies'
import storage from "./storage";

axios.defaults.timeout = 150000;

class HttpRequest {

  baseUrl = '';

  constructor() {
    this.baseUrl = "/server/api/";
    if (process.env.NODE_ENV === 'production') {
      this.baseUrl = "https://api.ankichinas.com/server/api/";
    } else {
      this.baseUrl = "https://file.ankichinas.cn/server/api/";
      // this.baseUrl = "https://dev-file.ankichinas.com/server/api/";
    }
    this.onRequest = false;
  }

  getInsideConfig() {
    return {
      baseURL: this.baseUrl,
      headers: {
        //
      }
    }
  }

  requestOver() {
    this.onRequest = false;

  }

  interceptors(instance) {
    this.onRequest = true;
    setTimeout(() => {
      if (this.onRequest) {

      }
    }, 800);
    // 请求拦截
    instance.interceptors.request.use(config => {
      let add = {};
      config.headers['authorization'] = VueCookies.get('authToken');
      if (config.method === 'post') {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        if (!config.data) {
          config.data = {};
        }
        config.data = Object.assign(config.data, add);
      } else {
        if (!config.params) {
          config.params = {};
        }

        config.params = Object.assign(config.params, add);
      }

      return config
    }, error => {
      this.requestOver();
      return Promise.reject(error)
    })
    // 响应拦截
    instance.interceptors.response.use(res => {
      this.requestOver();
      if (res.data) {
        const {data, code, message} = res.data;
        if (code === 0) {
          return Promise.resolve(data)
        }

        if (code === 401 || code === 403) {
          storage.setUserInfo(null);
          VueCookies.remove('authToken');
          if (location.href.indexOf('login') === -1) {
            location.replace('/login');
          }
          return;
        }

        return Promise.reject({code, message})
      }

      return Promise.reject({code: -1, message: '服务器出了点状况~'})
    }, (error) => {
      this.requestOver();
      console.log('error', error);
      return Promise.reject("网络不稳定，请重试~" + error)
    })
  }

  request(options) {
    const instance = axios.create({
      transformRequest: [function (data) {
        // 将数据转换为表单数据
        let ret = ''
        for (let it in data) {
          ret += '&' + encodeURIComponent(it) + '=' + encodeURIComponent(data[it])
        }
        return ret
      }]
    })
    options = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance);
    return instance(options)
  }
}

export default HttpRequest
