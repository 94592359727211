export default {
  areaList: [
    {"name": "中国", "code": 86},
    {"name": "中国澳门", "code": 853},
    {"name": "中国香港", "code": 852},
    {"name": "中国台湾", "code": 886},
    {"name": "俄罗斯", "code": 7},
    {"name": "日本", "code": 81},
    {"name": "韩国", "code": 82},
    {"name": "新加坡", "code": 65},
    {"name": "澳大利亚", "code": 61},
    {"name": "美国", "code": 1},
    {"name": "加拿大", "code": 1},
    {"name": "英国", "code": 44},
    {"name": "法国", "code": 33},
    {"name": "德国", "code": 49},
    {"name": "安圭拉岛", "code": 1264},
    {"name": "安提瓜和巴布达", "code": 1268},
    {"name": "巴哈马", "code": 1242},
    {"name": "巴巴多斯", "code": 1246},
    {"name": "百慕大", "code": 1441},
    {"name": "英属维京群岛", "code": 1284},
    {"name": "开曼群岛", "code": 1345},
    {"name": " 美属萨摩亚", "code": 1684},
    {"name": "多米尼克", "code": 1767},
    {"name": "多米尼加共和国", "code": 1809},
    {"name": "格林纳达", "code": 1473},
    {"name": "牙买加", "code": 1876},
    {"name": "蒙特塞拉特", "code": 1664},
    {"name": "波多黎各", "code": 1787 / 1939},
    {"name": "圣基茨和尼维斯", "code": 1869},
    {"name": "圣卢西亚", "code": 1758},
    {"name": "圣文森特和格林纳丁斯", "code": 1784},
    {"name": "特立尼达和多巴哥", "code": 1868},
    {"name": "特克斯和凯科斯群岛", "code": 1649},
    {"name": "美属维尔京群岛", "code": 1340},
    {"name": "关岛", "code": 1671},
    {"name": "北马里亚纳群岛", "code": 1670},
    {"name": " 埃及", "code": 20},
    {"name": "南苏丹", "code": 211},
    {"name": "摩洛哥", "code": 212},
    {"name": "阿尔及利亚", "code": 213},
    {"name": "突尼斯", "code": 216},
    {"name": "利比亚", "code": 218},
    {"name": "冈比亚", "code": 220},
    {"name": "塞内加尔", "code": 221},
    {"name": "毛里塔尼亚", "code": 222},
    {"name": "马里", "code": 223},
    {"name": "几内亚", "code": 224},
    {"name": "科特迪瓦", "code": 225},
    {"name": "布基纳法索", "code": 226},
    {"name": "尼日尔", "code": 227},
    {"name": "多哥", "code": 228},
    {"name": "贝宁", "code": 229},
    {"name": "毛里求斯", "code": 230},
    {"name": "利比里亚", "code": 231},
    {"name": "塞拉利昂", "code": 232},
    {"name": "加纳", "code": 233},
    {"name": "尼日利亚", "code": 234},
    {"name": "乍得", "code": 235},
    {"name": "中非共和国", "code": 236},
    {"name": "喀麦隆", "code": 237},
    {"name": "佛得角", "code": 238},
    {"name": "圣多美和普林西比", "code": 239},
    {"name": "赤道几内亚", "code": 240},
    {"name": "加蓬", "code": 241},
    {"name": "刚果共和国（布）", "code": 242},
    {"name": "刚果民主共和国（金）", "code": 243},
    {"name": "安哥拉", "code": 244},
    {"name": "几内亚比绍", "code": 245},
    {"name": "迪戈加西亚岛", "code": 246},
    {"name": "阿森松岛", "code": 247},
    {"name": "塞舌尔", "code": 248},
    {"name": "苏丹", "code": 249},
    {"name": "卢旺达", "code": 250},
    {"name": "埃塞俄比亚", "code": 251},
    {"name": "索马里", "code": 252},
    {"name": "吉布提", "code": 253},
    {"name": "肯尼亚", "code": 254},
    {"name": "坦桑尼亚", "code": 255},
    {"name": "乌干达", "code": 256},
    {"name": "布隆迪", "code": 257},
    {"name": "莫桑比克", "code": 258},
    {"name": "桑给巴尔", "code": 259},
    {"name": "赞比亚", "code": 260},
    {"name": "马达加斯加", "code": 261},
    {"name": "留尼汪", "code": 262},
    {"name": "津巴布韦", "code": 263},
    {"name": "纳米比亚", "code": 264},
    {"name": "马拉维", "code": 265},
    {"name": "莱索托", "code": 266},
    {"name": "博茨瓦纳", "code": 267},
    {"name": "斯威士兰", "code": 268},
    {"name": "科摩罗和马约特", "code": 269},
    {"name": "南非", "code": 27},
    {"name": "圣赫勒拿", "code": 290},
    {"name": "厄立特里亚", "code": 291},
    {"name": "阿鲁巴", "code": 297},
    {"name": "法罗群岛", "code": 298},
    {"name": "格陵兰", "code": 299},
    {"name": "希腊", "code": 30},
    {"name": "荷兰", "code": 31},
    {"name": "比利时", "code": 32},
    {"name": "西班牙", "code": 34},
    {"name": "直布罗陀", "code": 350},
    {"name": "葡萄牙", "code": 351},
    {"name": "卢森堡", "code": 352},
    {"name": "爱尔兰", "code": 353},
    {"name": "冰岛", "code": 354},
    {"name": "阿尔巴尼亚", "code": 355},
    {"name": "马耳他", "code": 356},
    {"name": "塞浦路斯", "code": 357},
    {"name": "芬兰", "code": 358},
    {"name": "保加利亚", "code": 359},
    {"name": "匈牙利", "code": 36},
    {"name": "东德", "code": 37},
    {"name": "立陶宛", "code": 370},
    {"name": "拉脱维亚", "code": 371},
    {"name": "爱沙尼亚", "code": 372},
    {"name": "摩尔多瓦", "code": 373},
    {"name": "亚美尼亚", "code": 374},
    {"name": "白俄罗斯", "code": 375},
    {"name": "安道尔", "code": 376},
    {"name": "摩纳哥", "code": 377},
    {"name": "圣马力诺", "code": 378},
    {"name": "梵蒂冈", "code": 379},
    {"name": "南斯拉夫", "code": 38},
    {"name": "乌克兰", "code": 380},
    {"name": "塞尔维亚", "code": 381},
    {"name": "黑山", "code": 382},
    {"name": "克罗地亚", "code": 385},
    {"name": "斯洛文尼亚", "code": 386},
    {"name": "波黑", "code": 387},
    {"name": "欧洲电话号码空间", "code": 388},
    {"name": "马其顿", "code": 389},
    {"name": "意大利", "code": 39},
    {"name": "罗马尼亚", "code": 40},
    {"name": "瑞士", "code": 41},
    {"name": "捷克斯洛伐克", "code": 42},
    {"name": "捷克共和国", "code": 420},
    {"name": "斯洛伐克", "code": 421},
    {"name": "列支敦士登", "code": 423},
    {"name": "奥地利", "code": 43},
    {"name": "丹麦", "code": 45},
    {"name": "瑞典", "code": 46},
    {"name": "挪威", "code": 47},
    {"name": "波兰", "code": 48},
    {"name": "福克兰群岛", "code": 500},
    {"name": "伯利兹", "code": 501},
    {"name": "危地马拉", "code": 502},
    {"name": "萨尔瓦多", "code": 503},
    {"name": "洪都拉斯", "code": 504},
    {"name": "尼加拉瓜", "code": 505},
    {"name": "哥斯达黎加", "code": 506},
    {"name": "巴拿马", "code": 507},
    {"name": "圣皮埃尔和密克隆群岛", "code": 508},
    {"name": " 海地", "code": 509},
    {"name": "秘鲁", "code": 51},
    {"name": "墨西哥", "code": 52},
    {"name": "古巴", "code": 53},
    {"name": "阿根廷", "code": 54},
    {"name": "巴西", "code": 55},
    {"name": " 智利", "code": 56},
    {"name": "哥伦比亚", "code": 57},
    {"name": "委内瑞拉", "code": 58},
    {"name": "瓜德罗普", "code": 590},
    {"name": "玻利维亚", "code": 591},
    {"name": "圭亚那", "code": 592},
    {"name": "厄瓜多尔", "code": 593},
    {"name": "法属圭亚那", "code": 594},
    {"name": "巴拉圭", "code": 595},
    {"name": "马提尼克", "code": 596},
    {"name": "苏里南", "code": 597},
    {"name": "乌拉圭", "code": 598},
    {"name": "荷属圣马丁", "code": 599},
    {"name": "库拉索", "code": 5999},
    {"name": "马来西亚", "code": 60},
    {"name": "印度尼西亚", "code": 62},
    {"name": "菲律宾", "code": 63},
    {"name": "新西兰", "code": 64},
    {"name": "泰国", "code": 66},
    {"name": "东帝汶", "code": 670},
    {"name": "南极洲、圣诞岛、科科斯群岛、诺福克岛", "code": 672},
    {"name": "文莱", "code": 673},
    {"name": "瑙鲁", "code": 674},
    {"name": "巴布亚新几内亚", "code": 675},
    {"name": "汤加", "code": 676},
    {"name": "所罗门群岛", "code": 677},
    {"name": "瓦努阿图", "code": 678},
    {"name": "斐济", "code": 679},
    {"name": "帕劳", "code": 680},
    {"name": "瓦利斯和富图纳群岛", "code": 681},
    {"name": "库克群岛", "code": 682},
    {"name": "纽埃", "code": 683},
    {"name": "萨摩亚", "code": 685},
    {"name": "基里巴斯，吉尔伯特群岛", "code": 686},
    {"name": "新喀里多尼亚", "code": 687},
    {"name": "图瓦卢，埃利斯群岛", "code": 688},
    {"name": "法属波利尼西亚", "code": 689},
    {"name": "托克劳群岛", "code": 690},
    {"name": "密克罗尼西亚联邦", "code": 691},
    {"name": "马绍尔群岛", "code": 692},
    {"name": "越南", "code": 84},
    {"name": "朝鲜", "code": 850},
    {"name": "柬埔寨", "code": 855},
    {"name": "老挝", "code": 856},
    {"name": "国际海事卫星组织 'SNAC' 卫星电话", "code": 870},
    {"name": "环球个人通讯服务", "code": 878},
    {"name": "孟加拉国", "code": 880},
    {"name": "移动卫星系统", "code": 881},
    {"name": "国际网络", "code": 882},
    {"name": "土耳其", "code": 90},
    {"name": "印度", "code": 91},
    {"name": "巴基斯坦", "code": 92},
    {"name": "阿富汗", "code": 93},
    {"name": "斯里兰卡", "code": 94},
    {"name": "缅甸", "code": 95},
    {"name": "马尔代夫", "code": 960},
    {"name": "黎巴嫩", "code": 961},
    {"name": "约旦", "code": 962},
    {"name": "叙利亚", "code": 963},
    {"name": "伊拉克", "code": 964},
    {"name": "科威特", "code": 965},
    {"name": "沙特阿拉伯", "code": 966},
    {"name": "也门", "code": 967},
    {"name": "阿曼", "code": 968},
    {"name": "也门民主共和国", "code": 969},
    {"name": "巴勒斯坦", "code": 970},
    {"name": "阿拉伯联合酋长国", "code": 971},
    {"name": "以色列", "code": 972},
    {"name": "巴林", "code": 973},
    {"name": "卡塔尔", "code": 974},
    {"name": "不丹", "code": 975},
    {"name": "蒙古", "code": 976},
    {"name": "尼泊尔", "code": 977},
    {"name": "国际费率服务", "code": 979},
    {"name": "伊朗", "code": 98},
    {"name": "国际电信公众通信服务试验", "code": 991},
    {"name": "塔吉克斯坦", "code": 992},
    {"name": "土库曼斯坦", "code": 993},
    {"name": "阿塞拜疆", "code": 994},
    {"name": "格鲁吉亚", "code": 995},
    {"name": "吉尔吉斯斯坦", "code": 996},
    {"name": "乌兹别克斯坦", "code": 998},
  ]
}