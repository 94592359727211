<template>
  <div class="w-500">
    <!-- 记忆卡显示区域 -->
    <div class="h-20">
      <div v-if="checkFldsLength" class="c-app-red text-align-center">{{ checkFldsLength }} 张卡牌解析错误</div>
    </div>
    <div class="justify-content-space-between mg-t-20 mg-b-6 align-items-center">
      <div class="text-strong mg-l-10">已生成{{ cardList.length || 0 }}张记忆卡，点击保存完成制作</div>
      <div class="impBtn" @click="importCard">
        <el-button type="primary" :disabled="cardList.length < 1">保存</el-button>
      </div>
    </div>
    <div class="pd-b-30 my-card-list">
      <div class="pd-20 bg-f align-items-center mg-b-20 bdr-10 shadow-2 "
           :class="getModel.flds.length!=item.length?'border-1 bdc-app-red':''" v-for="(item,index) in cardList"
           :key='index'>
        <div class="c-9 fs-40 mg-r-20">{{ index + 1 }}</div>
        <div class="justify-content-space-between flex-1 flex-wrap ">
          <div class="column " v-for="(cardItem,index) in item" :key="index"
               :class="getModel.flds.length > 2 ? 'w-b-31':'w-b-45'">
            <div class="c-9 fs-12 mg-t-10 mg-b-10" v-if="getModel.flds[index].name !== undefined">
              {{ getModel.flds[index].name ? getModel.flds[index].name : '' }}
            </div>
            <div class="text-ellipsis-2 text-strong">{{ cardItem }}</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
    >
      <div class="align-items-center column justify-content-center dialog-content">
        <div class="text-strong mg-b-10 fs-16 c-0" v-if="cardList.length > 0">成功导入{{ cardList.length }}卡牌 !</div>
        <div class="checkBtn mg-b-10">
          <el-button type="primary" @click.stop="toLearnNewCard">前往查看</el-button>
        </div>
        <div class="continueBtn" @click.stop="$router.go(0)">
          <el-button>继续制卡</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import request from '../../libs/api.request'

export default {
  props: {
    cardList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },

  methods: {
    importCard() {
      if (this.cardList.length > 0) {
        let loading = this.$loading({text: '加载中'});
        request.request({
          url: '/space.card/execImport',
          method: 'post',
          data: {
            cardList: JSON.stringify(this.cardList),
            mid: this.$store.state.models.id,
            did: this.$store.state.deck.id,
          }
        }).then(() => {
          loading.close();
          this.dialogVisible = true
        }).catch(err => {
          loading.close();
          this.$message({
            type: 'warning',
            message: err.message
          })
        })
      }
    },
    toLearnNewCard() {
      if (this.getDeck.topParentId !== 0) {
        this.$router.push({path: `/space/deck/${this.getDeck.topParentId}`})
      } else {
        this.$router.push({path: `/space/deck/${this.getDeck.id}`})
      }
    }
  },
  computed: {
    ...mapGetters(['getModel', 'getDeck']),
    checkFldsLength() {
      let errorCount = 0;
      this.cardList.forEach(v => {
        if (v.length !== this.getModel.flds.length) {
          errorCount++
        }
      });
      return errorCount;
    }
  },
}
</script>

<style scoped>
.impBtn >>> .el-button {
  border-radius: 20px;
  padding: 10px 20px;
}

.checkBtn >>> .el-button {
  width: 200px;
  border-radius: 10px;
  margin-top: 20px;

}

.continueBtn >>> .el-button {
  background-color: #ff9041;
  color: #fff;
  width: 200px;
  border-radius: 10px;
  margin: 20px 0;
}

.my-card-list {
  /* height: calc( 150vh - 100px ); */
  padding: 10px;
  max-height: calc(130vh - 100px);
  width: 510px;
  box-sizing: border-box !important;
  overflow: auto;
}
</style>
