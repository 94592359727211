<template>
  <div class="position-fixed t-0 b-0 l-0 r-0 bg-f9f pd-b-100 pd-t-15"
       style="z-index: 2000;overflow-y: scroll;">
    <div class="w-fill" style="display: table;height: 100%">
      <div style="display: table-cell;vertical-align: middle">
        <div class="justify-content-center">
          <div class="image-cover-container shadow-1 mg-t-44">
            <canvas id="image-cover-canvas" class="" :width="canvasW" :height="canvasH"></canvas>
          </div>
        </div>
      </div>
    </div>
    <div class="position-fixed t-0 h-55 w-fill fs-12 bg-f shadow-1 justify-content-center">
      <div class="align-items-center justify-content-space-between  pd-t-10 pd-b-10 pd-l-30 pd-r-30 flex-wrap"
           style="width: 800px">
        <div class="align-items-center">
          <div class="cursor-pointer mg-l-15" @click="clearCanvas" style="transform: rotate(-160deg)">
            <van-icon name="brush-o" size="24"/>
          </div>
          <div class="mg-l-15 mg-r-15 cursor-pointer" @click="undo"
               :style="stateArr.length > 1 ? 'opacity: 1':'opacity: 0.4'">
            <van-icon name="revoke" size="24" color="#222222"/>
          </div>
        </div>
        <div class="align-items-center">
          <div class="align-items-center">
            <div class="text-strong c-6 fs-16 cursor-pointer" @click="onInsertTextClick">文</div>
            <div class="w-1 bg-e8e h-15 mg-l-15 mg-r-15"></div>
            类型：
            <!--<div class="cursor-pointer"-->
            <!--@click="changeDrawType()">-->
            <!--<i v-if="drawType == 'pen'" class="el-icon-edit-outline fs-30 c-a"></i>-->
            <!--<div v-else>-->
            <!--<div class="pd-6" style="border: 2px #aaaaaa solid">-->
            <!--<div class="w-12 h-8 bg-c3c"></div>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--<div class="align-items-center">-->
            <!--类型：-->
            <!--<el-select v-model="drawType" size="small" class="w-80">-->
            <!--<el-option label="画笔" value="pen"></el-option>-->
            <!--<el-option label="矩形" value="rect"></el-option>-->
            <!--</el-select>-->
            <!--</div>-->
            <el-dropdown @command="onDrawTypeClick" :show-timeout="10">
              <span class="cursor-pointer">{{ drawType == 'pen' ? '画笔' : '矩形' }}<i
                  class="el-icon-arrow-down el-icon--right"></i></span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="pen">画笔</el-dropdown-item>
                <el-dropdown-item command="rect">矩形</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <template v-if="drawType == 'pen'">
            <div class="w-1 bg-e8e h-15 mg-l-15 mg-r-15"></div>
            大小：
            <!--<el-select v-model="penSize" size="small" class="w-80">-->
            <!--<el-option label="细" :value="2"></el-option>-->
            <!--<el-option label="中" :value="16"></el-option>-->
            <!--<el-option label="粗" :value="30"></el-option>-->
            <!--</el-select>-->
            <el-dropdown @command="onPenSizeClick" :show-timeout="10">
              <span class="cursor-pointer">{{ penSize }}px<i
                  class="el-icon-arrow-down el-icon--right"></i></span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in [2,6,10,16,22]" :command="item">
                  <div class="pd-10 align-items-center">
                    <div class="w-60 bg-9 bdr-20 mg-r-5" :style="'height:'+item+ 'px'"></div>
                    {{ item }}px
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <div class="w-1 bg-e8e h-15 mg-l-15 mg-r-15"></div>
          <div class="align-items-center ">
            颜色：
            <el-color-picker :predefine="colors" v-model="selectColor" size="medium"></el-color-picker>
          </div>

        </div>
        <div class="align-items-center">
          <el-button class="w-60 bdr-15 pd-2" size="small" @click="cancelImageEdit">
            <i class="el-icon-close text-strong fs-20 c-9"></i>
          </el-button>
          <el-button @click="finish" type="primary" size="small" class="w-60 bdr-15 pd-2">
            <i class="el-icon-check text-strong fs-20"></i>
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {fabric} from "fabric";
import storage from '../../libs/storage'

export default {
  name: "ImageCoverEditor",
  props: {
    doImageCoverSrc: {
      type: String,
      default: ''
    },
    doImageCoverSvg: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      canvasW: 0,
      canvasH: 0,
      canvas: null,
      isDrawing: false,
      mouseFrom: {}, // 鼠标绘制起点
      mouseTo: {}, // 鼠标绘制重点
      drawingObject: null,
      drawType: 'rect',
      colors: ['#C04A47', '#DAB834', '#81C66B', '#45A1EA', '#C14FBA', '#FF000E', '#2B2B2B', '#EEEEEE'],
      selectColor: '#81C66B',
      penSize: 2,
      stateArr: [],
      isRedoing: false,
      recordTimer: null,
      renderSetTimeout: null,
    }
  },
  watch: {
    penSize() {
      this.canvas.freeDrawingBrush.width = parseInt(this.penSize);
      this.changeOptions();
    },
    selectColor() {
      this.canvas.freeDrawingBrush.color = this.selectColor;
      this.changeOptions();
    },
    drawType() {
      if (this.drawType === 'pen') {
        // 设置绘画模式画笔类型为 铅笔类型
        this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas);
        // 设置画布模式为绘画模式
        this.canvas.isDrawingMode = true;
        // 设置绘画模式 画笔颜色与画笔线条大小
        this.canvas.freeDrawingBrush.color = this.selectColor;
        this.canvas.freeDrawingBrush.width = parseInt(this.penSize);
        // this.$message.success({message: '已切换为画笔', duration: 700, offset: 100});
      } else {
        this.canvas.isDrawingMode = false;
        // this.$message.success({message: '已切换为矩形', duration: 700, offset: 100});
      }
      this.changeOptions();
    }
  },
  methods: {
    onDrawTypeClick(command) {
      this.drawType = command;
    },
    onPenSizeClick(command) {
      console.log(command);
      this.penSize = parseInt(command);
    },
    changeOptions() {
      localStorage.setItem('imageCoverOptions', JSON.stringify({
        penSize: this.penSize,
        drawType: this.drawType,
        selectColor: this.selectColor
      }));
    },
    cancelImageEdit() {
      this.$emit('cancelImageEdit')
    },
    finish() {
      let svg = this.canvas.toSVG();
      svg = svg.substr(svg.indexOf('<svg'));
      svg = svg.replace(new RegExp(/<desc(?:(?!<\/desc>).|\n)*?<\/desc>/, 'gm'), '<desc data-image-cover=\'1\'></desc>');
      svg = svg.replace(new RegExp(/<defs(?:(?!<\/defs>).|\n)*?<\/defs>/, 'gm'), '');
      svg = svg.replace(new RegExp("\n", 'gm'), '');
      let svgEl = document.createElement('div');
      svgEl.innerHTML = svg;

      if (svgEl.getElementsByTagName('svg')[0].innerHTML.length < 10) {
        svg = '';
        this.canvas = null;
        this.$emit('finishImageCover', svg)
      } else {
        svgEl.getElementsByTagName('svg')[0].style = 'width:100%;height:100%;position:absolute;inset:0;';
        svgEl.getElementsByTagName('svg')[0].setAttribute('class', 'image-cover');
        svg = svgEl.innerHTML;
        let key = "imageCoverCount";
        let userInfo = storage.getUserInfo();
        let count = localStorage.getItem(key) || 0;
        count++;
        localStorage.setItem(key, count);
        if (userInfo.vip.vipAvailable || count % 2 === 0) {
          this.canvas = null;
          this.$emit('finishImageCover', svg)
        } else {
          if (count < 10) {
            this.$alert("图片编辑功能为VIP超级学霸功能，开通会员后无限制使用🌹", {
              title: '提示',
              confirmButtonText: '先试用一下',
              callback: (action) => {
                if (action === 'confirm') {
                  this.canvas = null;
                  this.$emit('finishImageCover', svg)
                }
              }
            })
          } else {
            this.$parent.$parent.onVipShowClick();
          }
        }
      }

    },
    undo() {
      this.isRedoing = true
      console.log(this.stateArr);
      if (this.stateArr.length > 1) {
        this.stateArr.pop();
        this.canvas.loadFromJSON(this.stateArr[this.stateArr.length - 1]);
      }
    },
    clearCanvas() {
      let children = this.canvas.getObjects()
      if (children.length > 0) {
        this.canvas.remove(...children)
      }
      this.$message.success({message: '画布已清空', duration: 1000, offset: 100});
    },
    onInsertTextClick() {
      this.$prompt('请输入内容', {
        confirmButtonText: '插入',
        cancelButtonText: '取消',
      }).then(({value}) => {
        this.insertText(value);
      }).catch(() => {

      });
    },
    insertText(content) {
      var text = new fabric.IText(content, {
        left: 50,
        top: 50
      });

      this.canvas.add(text);
    },
    initRect() {
      // 计算矩形长宽
      let left = 0;
      let top = 0;
      let width = (this.mouseTo.x - this.mouseFrom.x) / this.canvas.getZoom();
      let height = (this.mouseTo.y - this.mouseFrom.y) / this.canvas.getZoom();
      if (width < 0 || height < 0) {
        left = this.getTransformedPosX(this.mouseTo.x);
        top = this.getTransformedPosY(this.mouseTo.y);
        width = Math.abs(width);
        height = Math.abs(height);
      } else {
        left = this.getTransformedPosX(this.mouseFrom.x);
        top = this.getTransformedPosY(this.mouseFrom.y);
      }
      if (width < 5 || height < 5) {
        return;
      }
      let rectParams = {
        left: left,
        top: top,
        width: width,
        height: height,
        stroke: this.selectColor,
        fill: this.selectColor,
        strokeWidth: 5,
      };
      // 创建矩形 对象
      let canvasObject = new fabric.Rect(rectParams);
      if (this.drawingObject) {
        this.canvas.remove(this.drawingObject);
      }
      // 将绘制对象添加到 canvas中
      this.canvas.add(canvasObject);
      // 保存当前绘制的图形
      this.drawingObject = canvasObject;
    },
    getTransformedPosX(x) {
      let zoom = Number(this.canvas.getZoom())
      return (x - this.canvas.viewportTransform[4]) / zoom;
    },
    getTransformedPosY(y) {
      let zoom = Number(this.canvas.getZoom())
      return (y - this.canvas.viewportTransform[5]) / zoom;
    },
    cacheState(timeout) {
      console.log("cacheState");
      if (this.renderSetTimeout) {
        clearTimeout(this.renderSetTimeout);
      }
      this.renderSetTimeout = setTimeout(() => {
        this.stateArr.push(JSON.stringify(this.canvas));
        this.renderSetTimeout = null;
      }, timeout)
    }
  },
  mounted() {
    var img = document.createElement('img');
    img.src = this.doImageCoverSrc;
    img.onload = () => {
      let rate = img.width / img.height;
      let w1 = Math.min(img.width, window.innerWidth, 1000);
      let h2 = w1 / rate;
      this.canvasW = w1;
      this.canvasH = h2;
      this.stateArr = [];
      setTimeout(() => {
        var deleteIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
        var deleteImage = document.createElement('img');
        deleteImage.src = deleteIcon;

        function deleteObject(eventData, transform) {
          var target = transform.target;
          var canvas = target.canvas;
          canvas.remove(target);
          canvas.requestRenderAll();
        }

        function renderIcon(ctx, left, top, styleOverride, fabricObject) {
          var size = this.cornerSize;
          ctx.save();
          ctx.translate(left, top);
          ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
          ctx.drawImage(deleteImage, -size / 2, -size / 2, size, size);
          ctx.restore();
        }

        fabric.Object.prototype.controls.deleteControl = new fabric.Control({
          x: 0.5,
          y: -0.5,
          offsetY: 0,
          cursorStyle: 'pointer',
          mouseUpHandler: deleteObject,
          render: renderIcon,
          cornerSize: 24
        });
        fabric.Object.prototype.cornerColor = '#28bea0';
        fabric.Object.prototype.borderColor = '#28bea0';
        fabric.Object.prototype.cornerStyle = 'circle';
        fabric.Object.prototype.transparentCorners = false;
        this.canvas = new fabric.Canvas('image-cover-canvas');
        let match = this.doImageCoverSvg.match(/width=\"(.*?)\"/);
        if (match && match[1]) {
          this.canvas.setZoom(w1 / parseInt(match[1]));
        }
        this.canvas.selection = false;
        if (this.doImageCoverSvg) {
          let svg = this.doImageCoverSvg.replace(new RegExp('&nbsp;', 'gm'), '');
          fabric.loadSVGFromString(svg, (objects) => {
            objects.forEach((obj) => {
              this.canvas.add(obj)
            })
            this.canvas.renderAll()
          })
        } else {
          // this.drawType = 'rect';
        }
        this.stateArr.push(JSON.stringify(this.canvas));
        this.canvas.on("mouse:down", (options) => {
          if (this.canvas.getActiveObject() || !this.selectColor || this.drawType === 'pen')
            return;
          if (options.e.clientX === undefined) {
            this.mouseFrom.x = options.e.changedTouches[0].clientX - this.canvas._offset.left;
            this.mouseFrom.y = options.e.changedTouches[0].clientY - this.canvas._offset.top;
          } else {
            this.mouseFrom.x = options.e.clientX - this.canvas._offset.left;
            this.mouseFrom.y = options.e.clientY - this.canvas._offset.top;
          }
          this.isDrawing = true;
        });

        this.canvas.on("mouse:move", (options) => {
          if (this.canvas.getActiveObject() || this.drawType === 'pen' || !this.selectColor)
            return;
          if (options.e.clientX === undefined) {
            this.mouseTo.x = options.e.changedTouches[0].clientX - this.canvas._offset.left
            this.mouseTo.y = options.e.changedTouches[0].clientY - this.canvas._offset.top
          } else {
            this.mouseTo.x = options.e.clientX - this.canvas._offset.left
            this.mouseTo.y = options.e.clientY - this.canvas._offset.top
          }
          if (this.isDrawing) {
            this.initRect();
          }
        });

        this.canvas.on("mouse:up", (options) => {
          console.log("after:up");
          if (this.canvas.getActiveObject() || this.drawType === 'pen' || !this.selectColor)
            return;
          this.isDrawing = false;
          this.drawingObject = null;
          this.cacheState(100);
        });

        this.canvas.on("after:render", () => {
          if (this.isDrawing)
            return;
          if (!this.isRedoing) {
            console.log("after:render");
            this.cacheState(800);
          } else {
            // 当前正在执行撤销或重做操作，不记录重新绘制的画布
            this.isRedoing = false
          }
        })

        document.getElementById('image-cover-canvas').style.backgroundImage = "url(" + this.doImageCoverSrc + ")";
        document.getElementById('image-cover-canvas').style.backgroundSize = "contain";

        let options = localStorage.getItem('imageCoverOptions');
        if (options) {
          options = JSON.parse(options);
          if (options.drawType) {
            this.drawType = options.drawType;
          }
          if (options.selectColor) {
            this.selectColor = options.selectColor;
          }
          if (options.penSize) {
            this.penSize = parseInt(options.penSize);
          }
        }

      }, 10)
    }
  },
  created() {
    window.scrollTo(0, 0);
    document.body.style.cssText = 'overflow-y: hidden; height: 100%;';
  },
  destroyed() {
    document.body.style.cssText = 'height: 100%;';
  }
}
</script>

<style scoped>

#image-cover-canvas {
  background-size: contain;
}

.image-cover-container {
  background-color: white;
}

</style>
