/**
 * 判断是否是空
 * @param obj
 * @returns {boolean}
 */
let isEmpty = function (obj) {
    if (obj === null) return true;
    if (typeof obj === 'undefined') {
        return true;
    }

    if (typeof obj === "function") {
        return false;
    }

    if (typeof obj === 'string') {
        if (obj === "") {
            return true;
        }
        let reg = new RegExp("^([ ]+)|([　]+)$");
        return reg.test(obj);
    }

    if (obj instanceof Array && obj.length === 0) {
        return true;
    }

    return JSON.stringify(obj) == "{}";
};

let inWechat = function () {
    var ua = navigator.userAgent.toLowerCase();
    return ua.match(/MicroMessenger/i) == "micromessenger";
}

/*
 * 版本号比较方法
 * 传入两个字符串，当前版本号：curV；比较版本号：reqV
 * 调用方法举例：compare("1.1","1.2")，将返回false
 */
function compareVersionIfMax(curV, reqV) {
    if (curV && reqV) {
        //将两个版本号拆成数字
        var arr1 = curV.split('.'),
            arr2 = reqV.split('.');
        var minLength = Math.min(arr1.length, arr2.length),
            position = 0,
            diff = 0;
        //依次比较版本号每一位大小，当对比得出结果后跳出循环（后文有简单介绍）
        while (position < minLength && ((diff = parseInt(arr1[position]) - parseInt(arr2[position])) == 0)) {
            position++;
        }
        diff = (diff != 0) ? diff : (arr1.length - arr2.length);
        //若curV大于reqV，则返回true
        return diff > 0;
    } else {
        //输入为空
        console.log("版本号不能为空");
        return false;
    }
}

function compareArr(property, asc) {
    return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        if (asc === true) {
            // 升序排列
            return value1 - value2;
        } else {
            // 降序排列
            return value2 - value1;
        }
    }
}

let getFlagColor = function (flags) {
    flags = parseInt(flags);
    switch (flags) {
        case 0:
            return '#999999';
        case 1:
            return '#FF0000';
        case 2:
            return '#FFB100';
        case 3:
            return '#28BEA0';
        case 4:
            return '#1990FF';
    }
}

let strToTime = function (str) {
    var date = new Date();
    if (!isEmpty(str) && typeof (str) === 'string') {
        date = new Date(Date.parse(str.replace(/-/g, "/")));
    }

    return date.getTime() / 1000;
}

export default {isEmpty, inWechat, compareVersionIfMax, compareArr, getFlagColor, strToTime}
