<template>
  <div class="container">
    <iframe class="hide-scrollbar" :srcdoc="srcdoc" style="width: 100%;border: none;height: 100%"></iframe>
  </div>
</template>

<script>
  import htmlTpl from '../libs/HtmlTpl'


  export default {
    name: "Test",
    data() {
      return {
        srcdoc: `1123123`
      }
    },
    mounted() {
      let splitContent = htmlTpl.split("{{content}}");
      let content = `<script>

<\/script>`;
      this.srcdoc = splitContent[0] + content + splitContent[1];
    }
  }
</script>

<style scoped>

</style>
