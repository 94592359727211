<template>
  <div style="background: #f3f3f3"
       class="position-fixed t-0 b-0 l-0 r-0  z-index-999 justify-content-center pd-t-15 pd-b-20"
       :class="skinClass">
    <div class="w-fill h-fill bg-f bdr-15 position-relative column" style="max-width: 700px;">
      <div class="h-fill " style="overflow-y: hidden;border-top-left-radius:15px;border-top-right-radius: 15px ">
        <div class="text-align-center pd-10">{{ card.deckName }}</div>
        <iframe class="hide-scrollbar" :srcdoc="srcdoc" frameborder="0"
                style="width: 100%;border: none;height: 100%">
        </iframe>
      </div>
      <div v-if="cardStatus === 'qfmt'"
           class="h-60 justify-content-center align-items-center pd-t-8 pd-b-8 cursor-pointer position-relative"
           style="border-top: 1px #f3f3f3 solid" @click="onShowAnswerClick">
        <span>显示答案</span>
      </div>
      <div v-else class=" justify-content-center pd-t-8 pd-b-8" style="border-top: 1px #f3f3f3 solid;height: 80px">
        <div @click="onBtnClick(0)" id="forget-button"
             :style="answering?'opacity: 1.5':''"
             class="button justify-content-center align-items-center">
          <div class="text-align-center">
            <div class="align-items-center fs-14 text-strong">
              <img src="/static/image/edit_btn_forget_normal.png" class="w-14 h-14 mg-r-5">再来一次
            </div>
          </div>
        </div>
        <div @click="onBtnClick(1)" id="easy-button" :style="answering?'opacity: 1.5':''"
             class="button justify-content-center align-items-center">
          <div class="text-align-center">
            <div class="align-items-center fs-14 text-strong">
              <img src="/static/image/edit_btn_easy_normal.png" class="w-14 h-14 mg-r-5">学会了
            </div>
          </div>
        </div>
      </div>
      <div class="position-absolute b-50 w-100" style="right: -100px;">
        <div class="position-relative mg-l-50">
          <div v-for="(option,key,index) in options" @click.stop="onCardOptionClick(option)"
               class="options-item position-absolute bg-f w-48 h-48 bdr-circular cursor-pointer z-index-1 align-items-center justify-content-center"
               :class="{'shadow-2':optionsSwitch}"
               :style="'bottom:'+((option.ord+1)*(optionsSwitch?70:0))+'px'"
               :key="index">
            <img v-if="option.icon" :title="option.name"  :src="option.icon" width="26" height="26">
          </div>
          <div :style="optionsSwitch?'':'transform: rotate(180deg)'"
               class="position-absolute b-0 w-48 h-48 cursor-pointer justify-content-center align-items-center  z-index-99"
               :class="{'shadow-2 bg-f bdr-circular ':!optionsSwitch}" @click="optionsSwitch = !optionsSwitch">
            <i class="el-icon-arrow-down fs-20 c-c text-strong"></i>
          </div>
        </div>
      </div>
    </div>
    <remark-dialog ref="remarkDialog" :card-id="card.id"></remark-dialog>
  </div>
</template>

<script>
import request from '../libs/api.request'
import htmlTpl from '../libs/HtmlTpl'
import util from '../libs/util'
import RemarkDialog from "@/components/RemarkDialog";

export default {
  name: "studyViewer",
  components: {RemarkDialog},
  props: {
    did: {
      type: String,
      default: ''
    },
    cardList: {
      type: Array,
      default: () => {
      }
    },
    currentCardIndex: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      srcdoc: '',
      currentSkin: 'sun',
      currentIndex: this.currentCardIndex,
      util: util,
      options: {
        close: {ord: 0, id: 'close', name: '退出学习', icon: '/static/image/side_toolbar_closed_normal.png'},
        skin: {ord: 1, id: 'skin', name: '模式', icon: '/static/image/side_toolbar_skin_light_normal.png'},
        remark: {ord: 2, id: 'remark', name: '助记', icon: '/static/image/icon_note_zhuji@2x.png'},
      },
      optionsSwitch: true,
      note: {},
      models: {},
      colConf: {},
      colUsn: 0,
      dconfs: {},
      decks: {},
      crtDue: 0,
      cardStatus: 'qfmt',//qfmt 正面  afmt 背面
      card: {},
      currentModel: {},
      skinClass: '',
      answering: false,//答案提交中
      useTime: 0,
      internal: null,
      visibleFlags: false,
    }
  },
  methods: {
    onBtnClick(status) {
      if (status == 0) {//重来
        this.cardList.push(this.card);
        this.currentIndex++;
        this.getStudyCard();
      } else if (status == 1) {//学会了
        if (this.currentIndex < this.cardList.length - 1) {
          this.currentIndex++;
          this.getStudyCard();
        } else {
          if (this.currentIndex == this.cardList.length - 1) {
            this.$alert('已完成学习');
            this.$alert('恭喜您，学习完了', {callback: () => this.$emit('close-click')})
          }
        }
      }
    },
    onCardOptionClick(option) {
      switch (option.id) {
        case 'close':
          this.$emit('close-click');
          break;
        case 'skin':
          if (this.currentSkin === 'sun') {
            this.options.skin.icon = '/static/image/side_toolbar_skin_dark_normal.png';
            this.currentSkin = 'moon';
            this.skinClass = 'filter-invert skin-moon';
          } else {
            this.options.skin.icon = '/static/image/side_toolbar_skin_light_normal.png';
            this.currentSkin = 'sun';
            this.skinClass = 'skin-sun';
          }
          break;
        case 'remark':
          this.$refs.remarkDialog.open();
          break;
      }
    },
    onShowAnswerClick() {
      this.cardStatus = 'afmt';
      this.rendHtml();
    },
    getStudyData() {
      let loading = this.$loading();
      request.request({
        url: '/space.deck/getStudyData',
        params: {did: this.did},
      }).then((data) => {
        this.models = data.models;
        this.dconfs = data.dconfs;
        this.decks = data.decks;
        this.colConf = data.colConf;
        this.crtDue = data.crtDue;
        this.colUsn = data.colUsn;
        this.getStudyCard();
        loading.close();
      }).catch((e) => {
        loading.close();
        this.$alert(e.message, {callback: () => location.reload()})
      })
    },
    getStudyCard() {
      this.answering = true;
      if (!this.cardList[this.currentIndex].flds) {
        request.request({
          url: '/space.card/getFlds',
          params: {noteId: this.cardList[this.currentIndex].nid}
        }).then((data) => {
          this.card = this.cardList[this.currentIndex]
          this.card.flds = data.flds
          this.showCard()
        }).catch((e) => {
          this.$alert(e.message, {callback: () => location.reload()})
        })
      } else {
        this.card = this.cardList[this.currentIndex]
        this.showCard()
      }
    },
    findHideIfHas(content, name, value, start) {
      if (start === undefined) {
        start = -1;
      }

      let startPos = content.indexOf('{{^' + name + '}}', start);
      if (startPos > -1) {
        if (value === '') {
          content = content.replace('{{^' + name + '}}', '');
          content = content.replace('{{/' + name + '}}', '');
          if (content.indexOf('{{^' + name + '}}') > -1) {
            content = this.findHideIfHas(content, name, value)
          }
        } else {
          let endPos = content.indexOf('{{/' + name + '}}', startPos);
          if (endPos > 0) {
            content = content.substr(0, startPos) + content.substr(endPos + ('{{/' + name + '}}').length);
            if (content.indexOf('{{^' + name + '}}', endPos)) {
              content = this.findHideIfHas(content, name, value, endPos)
            }
          }
        }
      }

      return content;
    },
    findHideIfEmpty(content, name, value, start) {
      if (start === undefined) {
        start = -1;
      }

      let startPos = content.indexOf('{{#' + name + '}}', start);
      if (startPos > -1) {
        if (value !== '') {
          content = content.replace('{{#' + name + '}}', '');
          content = content.replace('{{/' + name + '}}', '');
        } else {
          let endPos = content.indexOf('{{/' + name + '}}', startPos);
          if (endPos > 0) {
            content = content.substr(0, startPos) + content.substr(endPos + ('{{/' + name + '}}').length);
            if (content.indexOf('{{#' + name + '}}') > -1) {
              content = this.findHideIfEmpty(content, name, value)
            }
          }
        }
      }

      return content;
    },
    findHint(content, name, value) {
      let id = Math.round(Math.random() * 10000000);
      let hint = `<a class=hint href= "#"
          onclick="this.style.display='none';document.getElementById('hint${id}').style.display='block';return false;">
          显示${name}
          </a >
          <div id="hint${id}" class=hint style="display: none">${value}</div>`;
      content = content.replace("{{hint:" + name + "}}", hint);
      if (content.indexOf("{{hint:" + name + "}}") > -1) {
        content = this.findHint(content, name, value);
      }
      return content;
    },
    findType(content, name, value) {
      if (this.cardStatus === 'qfmt') {
        sessionStorage.setItem("type_input_value", "");
        let inputName = 'input_' + new Date().getTime();
        let replace = `<div style="text-align: center;margin-top: 20px">
<input id="${inputName}_answer" name="answer" style="width: 90%;padding: 10px" placeholder="请输入答案" onkeydown="mOnKeyDown()"/>
<script>
    let ${inputName}_answer = document.getElementById('${inputName}_answer');
    ${inputName}_answer.oninput = function(){
      sessionStorage.setItem("type_input_value",${inputName}_answer.value);
    }
<\/script>
`;
        content = content.replace("{{type:" + name + "}}", replace);
      } else {
        let inputValue = sessionStorage.getItem("type_input_value");
        if (inputValue === undefined || inputValue === null || inputValue === "") {
          inputValue = "&nbsp;&nbsp;&nbsp;";
        }

        let replace = "<div style='margin-top: 20px'><span style='padding: 5px;background-color: " + (inputValue === value ? '#28BEA0' : '#FF0000') + "'>" + inputValue + "</span></div>";
        replace += "<div style='margin: 10px'>↓</div>";
        replace += "<div><span style='padding: 5px;background-color: #f3f3f3'> " + value + "</span></div>";
        content = content.replace("{{type:" + name + "}}", replace);
      }
      return content;
    },
    showCard() {
      if (this.card) {
        this.card.ivl = parseInt(this.card.ivl);
        this.card.left = parseInt(this.card.left);
        this.card.due = parseInt(this.card.due);
        this.card.factor = parseFloat(this.card.factor);
      }

      this.useTime = 0;
      if (this.internal == null) {
        this.internal = setInterval(() => {
          this.useTime = this.useTime + 1;
        }, 1000);
      }
      this.currentModel = this.models[this.card.mid];
      this.cardStatus = 'qfmt';
      this.rendHtml();
    },
    findMedia(content) {
      let arr = content.match(/\[sound:([\w\S\s]+?)\]/gi);
      if (arr && arr.length > 0) {
        arr.forEach((v, k) => {
          let src = v.substring(7, v.length - 1);
          let id = Math.round(Math.random() * 10000000);
          let replace;
          if (k === 0) {
            replace = `<i class='iconfont icon-play'  onclick='mPlayAudio("${id}")' style='padding: 5px;font-size: 20px;cursor: pointer'><audio autoplay id='${id}' class='ak-play-audio'><source src="/${src}"></audio></i>`;
          } else {
            replace = `<i class='iconfont icon-play'  onclick='mPlayAudio("${id}")' style='padding: 5px;font-size: 20px;cursor: pointer'><audio class='ak-play-audio' id='${id}'><source src="/${src}"></audio></i>`;
          }
          content = content.replace(v, replace);
        });
      }
      return content;
    },
    rendHtml() {
      let html = htmlTpl;
      let tmplsIndex = Math.min(this.card.ord ? parseInt(this.card.ord) : 0, this.currentModel.tmpls.length - 1);
      let content = this.currentModel.tmpls[tmplsIndex][this.cardStatus];
      if (this.cardStatus === 'afmt') {
        content = content.split("{{FrontSide}}").join(this.currentModel.tmpls[tmplsIndex]['qfmt']);
        html = html.replace("::class::", 'ck-back');
      } else {
        html = html.replace("::class::", 'ck-front');
      }
      content = content.replace(new RegExp("{{=<% %>=}}", "gm"), "");
      content = content.replace(new RegExp("<%", "gm"), "{{");
      content = content.replace(new RegExp("%>", "gm"), "}}");
      let hasCloze = content.indexOf('{{cloze') > -1;//  挖空模板
      let needFindType = content.indexOf('{{type:') > -1; //   输入答案模板
      let needFindHint = content.indexOf('{{hint:') > -1; //   点击显示模板
      this.currentModel.flds.forEach((v, k) => {
        content = content.replace(new RegExp("{{furigana:" + v.name + "}}", "gm"), this.card.flds[k]);
        content = content.replace(new RegExp("{{kana:" + v.name + "}}", "gm"), this.card.flds[k]);
        content = content.replace(new RegExp("{{" + v.name + "}}", "gm"), this.card.flds[k]);
        content = content.replace(new RegExp("{{ " + v.name + " }}", "gm"), this.card.flds[k]);
        content = content.replace(new RegExp("{{edit:" + v.name + "}}", "gm"), this.card.flds[k]);
        if (hasCloze) {
          if (v.name !== 'Text' && v.name.indexOf('Text') === 0) {
            let txtOrd = parseInt(v.name.replace('Text', ''));
            content = content.replace(new RegExp("{{cloze:" + v.name + "}}", "gm"), txtOrd === parseInt(this.card.ord) + 1 ? this.card.flds[k] : '');
          } else {
            content = content.replace(new RegExp("{{cloze:" + v.name + "}}", "gm"), this.card.flds[k]);
          }
        }
        content = this.findHideIfEmpty(content, v.name, this.card.flds[k]);
        content = this.findHideIfHas(content, v.name, this.card.flds[k]);
        if (needFindHint) {
          content = this.findHint(content, v.name, this.card.flds[k]);
        }
        if (needFindType) {
          content = this.findType(content, v.name, this.card.flds[k]);
        }
      });
      content = content.replace(new RegExp("{{Tags}}", "gm"), this.card.tags);
      content = content.replace(new RegExp("{{Deck}}", "gm"), this.decks[this.card.did].name);
      content = content.replace(new RegExp("{{Subdeck}}", "gm"), this.decks[this.card.did].name);
      content = this.findHideIfEmpty(content, 'Tags', this.card.tags);
      content = this.findHideIfHas(content, 'Tags', this.card.tags);
      content = this.findMedia(content);
      let arr = content.match(/\{\{c([\w\W]*?)\}\}/gi);
      if (arr) {
        arr.forEach((v, k) => {
          let newArr = v.substr(3, v.length - 5).split('::');
          if (newArr.length === 2) {
            if (this.cardStatus === 'afmt') {
              if (!hasCloze || parseInt(newArr[0]) === (parseInt(this.card.ord) + 1)) {
                content = content.replace(v, '<span class="cloze">' + newArr[1] + '</span>')
              } else {
                content = content.replace(v, newArr[1])
              }
            } else {
              if (!hasCloze || parseInt(newArr[0]) === (parseInt(this.card.ord) + 1)) {
                content = content.replace(v, '<span class="cloze">[...]</span>')
              } else {
                content = content.replace(v, newArr[1])
              }
            }
          }
        })
      }
      let splitContent = html.split("{{content}}");
      html = splitContent[0] + content + splitContent[1];
      let style = this.currentModel['css'];
      let splitStyle = html.split("{{style}}");
      html = splitStyle[0] + style + splitStyle[1];
      html = html.replace("{{script}}", "");
      html = html.replace(new RegExp("&nbsp;", "gm"), " ");
      this.srcdoc = html;
    },
    handleKeyDown(e) {
      let that = this;
      let key = e.key;
      console.log(key);
      if (key == 'Enter' && that.cardStatus == 'qfmt') {
        that.onShowAnswerClick();
      }
    },
  },
  mounted() {
    this.getStudyData();
  },
  created() {
    /** 阻止页面滚动 */
    document.body.style.cssText = 'overflow-y: hidden; height: 100%;';
    window.addEventListener("keydown", this.handleKeyDown, true);
  },
  destroyed() {
    if (this.internal) {
      clearInterval(this.internal);
      this.internal = null;
    }
    /** 取消阻止页面滚动 */
    document.body.style.cssText = 'overflow-y: auto; height: 100%;'
  }
}
</script>

<style scoped>
.button {
  cursor: pointer;
  flex: 1;
  background-color: #F7F7F7;
  border: 2px solid #F7F7F7;
  margin: 8px;
  border-radius: 10px;
}

.button .time {
  color: #999999;
}

#easy-button:hover {
  border: 2px solid #28BEA0;
}

#good-button:hover {
  border: 2px solid #018DFF;
}

#forget-button:hover {
  border: 2px solid #FF5507;
}

#hard-button:hover {
  border: 2px solid #FFB200;
}

.options-item {
  transition: bottom 0.5s;
}

.iconfont-l {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  padding: 5px 30px;
  color: #ccc;
}

.iconfont-r {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  padding: 5px 30px;
  color: #ccc;
}
</style>
