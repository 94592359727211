<template>
  <div class="makeContainer pd-b-30">
    <div class="bg-f pd-20 justify-content-space-between" style="height: 100%">
      <div class="make-left">
        <!-- 批量制卡描述 -->
        <div class="el-icon-arrow-left mg-b-20 mg-l-10 fs-20 c-app-green cursor-pointer" @click="$router.push({path: '/space'})"></div>
        <div class="pd-l-10 w-650 display-flex mg-b-20">
          <h2 class="fs-20 mg-0">批量制卡</h2>
          <div class="mg-l-20 fs-14 c-b mg-t-10 display-flex lh-17">
            <span>适用于将笔记和文件批量转行成ANKI记忆卡 </span>
            <el-popover
              placement="top-start"
              title="批量制卡教程"
              width="400"
              trigger="hover"
              content="">
              <div class="lh-20">
                模版，挖空等批量制卡详细教程，请点击下方链接查看:<br> <a href="https://support.qq.com/products/332614/faqs/108831" target="_blank">https://support.qq.com/products/332614/faqs/108831</a>
              </div>
              <svg slot="reference" t="1639535021057" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2355" width="17" height="17"><path d="M512 85.333333c235.637333 0 426.666667 191.029333 426.666667 426.666667S747.637333 938.666667 512 938.666667 85.333333 747.637333 85.333333 512 276.362667 85.333333 512 85.333333z m0 586.666667a32 32 0 1 0 0 64 32 32 0 0 0 0-64z m-2.517333-373.333333c-48.416 0-92.746667 24.16-118.613334 63.413333a137.088 137.088 0 0 0-15.978666 33.237333 32 32 0 0 0 60.906666 19.690667c2.016-6.24 4.885333-12.202667 8.522667-17.717333C458.4 375.914667 482.709333 362.666667 509.482667 362.666667 552.277333 362.666667 586.666667 396.266667 586.666667 437.333333s-34.4 74.666667-77.194667 74.666667a32 32 0 0 0-32 32v64a32 32 0 0 0 64 0v-35.584C603.946667 558.197333 650.666667 503.232 650.666667 437.333333c0-76.757333-63.381333-138.666667-141.194667-138.666666z" p-id="2356" fill="#bfbfbf"></path></svg>
            </el-popover>
          </div>
        </div>
        <!-- 设置模板 -->
        <div class="mg-b-20 shadow-2 bdr-10 pd-20 w-650 box">
          <h3 class="mg-0 mg-b-20">一 设置要保存的位置和模板类型</h3>
          <select-model @setModelsData="setModelsData"></select-model>
        </div>

        <!-- 解析或导入文件区域 -->
        <div class="justify-content-space-between">
          <!-- 制作卡牌区域 -->
          <div class="box shadow-2 bdr-10 pd-20 position-relative w-650">
            <h3 class="mg-0 mg-b-20">二 录入笔记或导入文件</h3>
            <div class="justify-content-space-between mg-b-20" v-if="isShowEdit">
              <div class="c-app-green cursor-pointer w-350"  @click="toggleControl()">导入文件
              (支持Excel、Csv表格) <i class="iconfont icon-shangchuan-"></i>
              </div>
              <div class="c-app-green cursor-pointer " @click="showRule">查看规则</div>
            </div>
            <div v-else class="justify-content-space-between mg-b-20">
              <div class="c-app-green cursor-pointer w-100" @click="toggleControl()">切换至编辑器</div>
              <div class="c-app-green cursor-pointer mg-r-40" @click="showRule">查看规则</div>
            </div>
            <!-- 编辑器区域 -->
            <edit-card v-if="isShowEdit" @handleCardList="handleCardList" :model="model"></edit-card>
            <!-- 上传区域 -->
            <my-upload v-else @upLoadSuccess="upLoadSuccess" :model="model"></my-upload>
          </div>
        </div>
      </div>
      <!-- 预览区域 -->
      <div class="w-530 box card-preview">
        <!-- 记忆卡区域 -->
        <memory-card :cardList="cardList"></memory-card>
      </div>
    </div>
    <el-dialog
      title="匹配规则"
      :visible.sync="ruleDialogVisible"
      width="48%"
      >
      <div>
        <match-rule :title="selectRule"></match-rule>
      </div>
    </el-dialog>
  </div>
</template>

<script>

  import selectDeck from '../components/SelectDeck.vue'
  import SelectModel from '../components/select-drawer/SelectModel.vue'
  import MatchRule from '../components/make/MatchRule.vue'
  import EditCard from '../components/make/EditCard.vue'

  import MyUpload from '../components/upload/MyUpload.vue'
  import MemoryCard from '../components/make/MemoryCard.vue'

  export default {
    name: "Make",
    components: {
      selectDeck,
      SelectModel,
      MatchRule,
      EditCard,
      MemoryCard,
      MyUpload
    },
    data() {
      return {
        isShowEdit: true,
        showSelectModelDrawer: false,
        dialogVisible: false,
        ruleDialogVisible:false,
        cardList: [],
        model: {},
        content:'模版，挖空等批量制卡详细教程，请点击链接查看: <a href="https://support.qq.com/products/332614/faqs/108831" target="_blank"></a>'
      }
    },
    methods: {
      // 控制切换隐藏
      toggleControl() {
        this.isShowEdit = !this.isShowEdit
      },
      handleCardList(cardList) {
          this.cardList = cardList
      },
      setModelsData(model) {
        this.model = model
      },
      //上传成功
      upLoadSuccess(cardList) {
        this.cardList = cardList
      },
      showRule() {
        if (this.model.flds) {
          this.ruleDialogVisible = true
        }else {
          this.$message({
            type:'warning',
            message:'请选择模版'
          })
        }
      }
    },
    computed: {
      selectRule() {
        return this.isShowEdit ? '字段对应的标识符' : '表格匹配'
      }
    },
    mounted() {
      if(this.$store.state.models) {
        this.model = this.$store.state.models
      }
    }

  }
</script>

<style scoped>
.lh-17 {
  line-height: 17px;
}
  .makeContainer {
    width: 1200px;
    /* height: 100%; */
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: -80px;
  }

   .card-preview {
    word-break: break-all;
  }

  .firstItem {
    border-bottom: 1px solid #f3f3f3;
  }

  .iconfont {
    margin-left: 10px;
    vertical-align: middle;
    font-size: 18px;
  }

  .loadingBtn >>> .el-button {
    width: 500px;
    border-radius: 10px;
  }

  .doneBtn >>> .el-button {
    background-color: #ff9041;
    color: #fff;
    width: 500px;
    border-radius: 10px;
  }

  .checkBtn >>> .el-button {
    width: 200px;
    border-radius: 10px;
  }

  .continueBtn >>> .el-button {
    background-color: #ff9041;
    color: #fff;
    width: 200px;
    border-radius: 10px;
    margin-top: 10px;
  }

  .my-btn-l {
    margin-right: -65px;
  }

  .my-btn-r {
    margin-left: -65px;
  }
</style>
