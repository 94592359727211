<template>
  <div class="card-editor">
    <div class="position-fixed t-0 b-0 l-0 r-0 bg-f3f " style="z-index:1000;overflow-y: scroll">
      <div class="bg-f  position-relative"
           style="width: 720px;margin: 0 auto;min-height: 100%;">
        <div class="hide-scrollbar">
          <div class="pd-b-100">
            <div v-for="v,k in editors" class="pd-t-10 editor" v-show="tpl[k]"
                 :class="currentEditorIndex === k?'current-editor':'not-current-editor'" :key="k">
              <div class="display-flex" style="flex-wrap: wrap;">
                <div v-if="tpl[k]" :class="currentEditorIndex === k ?'display-none':''"
                     class="mg-b-5 fs-16 mg-l-12 lh-40 text-strong" style="white-space: nowrap;">
                  {{ tpl[k].name }}
                </div>
              </div>
              <div :id="'card-edit-' + k"></div>
            </div>
          </div>
        </div>

      </div>
      <div class="position-fixed b-0 w-fill bottomNav">
        <div style="width: 700px;margin: 0 auto;" class="bg-f c-9 position-relative">
          <div class="align-items-center justify-content-space-between">
            <div class="align-items-center mg-l-20">
              <a class="c-app-green text-ellipsis-1" @click="onSelectDeckClick">
                <span v-if="deck"><i class="el-icon-caret-bottom"></i>{{ deck.simpleName }}</span>
                <span v-else><i class="el-icon-caret-bottom"></i>选择记忆库</span>
              </a>
              <a @click="onSelectModelClick" class="mg-l-20 c-app-green text-ellipsis-1">
                <span v-if="model"><i class="el-icon-caret-bottom"></i>{{ model.name }}</span>
                <span v-else><i class="el-icon-caret-bottom"></i>选择模板</span>
              </a>
            </div>
            <div class="h-60 align-items-center mg-r-20 mg-l-50">
              <!-- <a v-if="this.card.id>0" class="mg-r-30 c-app-red w-40" @click="onDeleteClick">
                删除
              </a> -->
              <a class="c-app-green w-70" @click="toMakeCard">
                批量制卡
              </a>
              <el-button @click="onSaveCardClick" type="primary" class="w-46 bdr-30 pd-2 mg-l-30">
                <i class="el-icon-check text-strong fs-18"></i>
              </el-button>
            </div>
          </div>
          <!-- 右侧图标按钮区域 -->
          <div class="position-absolute b-70 r-0">
            <div class="position-relative">
              <div v-for="(option,key,index) in options" @click="onCardOptionClick(option)"
                   class="options-item position-absolute mg-l-50 bg-f w-48 h-48 bdr-circular cursor-pointer z-index-1 align-items-center justify-content-center"
                   :class="{'shadow-2':optionsSwitch}"
                   :style="'bottom:'+((option.ord+1)*(optionsSwitch?70:0))+'px'"
                   :key="index">
                <div>
                  <img :src="option.icon" width="26" height="26">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <el-drawer
        title="选择记忆库"
        :visible.sync="showSelectDeckDrawer"
        :size="400"
        direction="ltr">
      <div class="pd-20" style="overflow-y: scroll">
        <select-deck v-on:on-item-select="onDeckSelect" :decks="decks" :open="true"></select-deck>
      </div>
    </el-drawer>
    <el-drawer
        title="切换模板"
        :visible.sync="showSelectModelDrawer"
        :size="400"
        direction="ltr">
      <div class="pd-20">
        <div v-for="model in models" :key="model.id"
             class="align-items-center mg-b-20 pd-20 bg-f4f bdr-10 model-item cursor-pointer">
          <span class="flex-1 " @click="onModelSelect(model)"> {{ model.name }}
          <i v-if="model.usedCount !== undefined" class="fs-12 c-9">{{ model.usedCount }}卡牌</i>
          </span>
          <span v-if="!model.gf" class="del" @click="onModelDelete(model)">删除</span>
        </div>
      </div>
    </el-drawer>
    <image-cover-editor v-if="doImageCoverSrc" :doImageCoverSrc="doImageCoverSrc" :doImageCoverSvg="doImageCoverSvg"
                        @cancelImageEdit="cancelImageEdit" @finishImageCover="finishImageCover"
                        @onVipShow="onVipShow"
    >
    </image-cover-editor>
  </div>
</template>

<script>

import SelectDeck from '../components/SelectDeck'
import request from '../libs/api.request'
import ImageCoverEditor from "@/components/edit-plugin/ImageCoverEditor";

export default {
  components: {
    ImageCoverEditor,
    SelectDeck
  },
  name: "CardEditor",
  data() {
    window.addEventListener('keydown', e => {
      //mac 上是metaKey 是按住command
      if ((e.ctrlKey && e.code === 'KeyS') || (e.metaKey && e.code === 'KeyS')) {
        console.log('ctrl + s');
        this.onSaveCardClick()
        e.preventDefault()
      }
    })
    return {
      showSelectDeckDrawer: false,
      showSelectModelDrawer: false,
      currentEditorIndex: '',
      editors: [],
      deck: {},
      model: {},
      tpl: [{name: '正面', value: ''}, {name: '背面', value: ''}],
      saveData: {},
      hasChange: false,
      options: {
        close: {ord: 0, id: 'close', name: '退出编辑', icon: '/static/image/side_toolbar_closed_normal.png'},
        destroy: {ord: 1, id: 'destroy', name: '删除', icon: '/static/image/side_toolbar_delete_normal.png'},
        preview: {ord: 2, id: 'preview', name: '预览', icon: '/static/image/side_toolbar_preview_normal.png'},
      },
      optionsSwitch: true,
      doImageCoverElement: null,
      doImageCoverEditor: null,
      doImageCoverSrc: '',
      doImageCoverSvg: '',
    }
  },
  props: {
    card: {
      type: Object,
      default: {}
    },
    decks: {
      type: Object,
      default: {}
    },
    models: {
      type: Object,
      default: {}
    },
  },
  methods: {
    onVipShow() {
      this.$parent.onVipShowClick()
    },
    toMakeCard() {
      this.$router.push({path: '/make'})
    },
    onCardOptionClick(option) {
      if (option.id === 'preview') {
        this.$emit('toPreview', this.card)
        // this.$emit('close-click');
      } else if (option.id === 'close') {
        this.onCloseClick()
      } else {
        this.onDeleteClick()
      }
    },
    onAkCoverClick(e) {
      console.log(e.target.style.color);
      // if (e.target.style.color == 'rgb(0, 0, 0)') {
      //   e.target.style.color = '#F4EDB3';
      // } else {
      //   e.target.style.color = '#000000';
      // }
      console.log('>>>' + new Date().getTime());
    },
    onDeleteClick() {
      this.$confirm('是否删除该卡牌?', '提示', {
        confirmButtonText: '删除',
        confirmButtonClass: 'bg-app-red bdc-app-red',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            let loading = this.$loading();
            request.request({
              url: '/space.card/deleteCard',
              method: 'post',
              data: {id: this.card.id,}
            }).then((data) => {
              done();
              loading.close();
              this.$emit('close-click', {type: 'delete', id: this.card.id});
            }).catch((e) => {
              done();
              loading.close();
              this.$message.error(e);
            });
            done();
          } else {
            done();
          }
        }
      })
    },
    onCloseClick() {
      if (this.hasChange) {
        this.$confirm('内容未保存，是否要退出编辑?', '提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'info',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              this.hasChange = false;
              this.$emit('close-click');
              done();
            } else {
              done();
            }
          }
        })
      } else {
        this.$emit('close-click');
      }
    },
    onSelectDeckClick() {
      this.showSelectDeckDrawer = true;
    },
    onSelectModelClick() {
      this.showSelectModelDrawer = true;
    },
    onDeckSelect(deck) {
      this.deck = deck;
      this.card.did = deck.id;
      this.showSelectDeckDrawer = false;
    },
    onModelSelect(model) {
      this.model = model;
      this.card.mid = model.id;
      console.log(">>>>>>>>>>>>!!!");
      console.log(this.card);
      setTimeout(() => {
        this.showSelectModelDrawer = false;
        this.initEditor();
      }, 150)
    },
    onModelDelete(model) {
      this.$confirm('是否删除模版：' + model.name + ' ？', '提示', {
        confirmButtonText: '确认删除',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            let loading = this.$loading();
            request.request({
              url: '/space.card/deleteModel',
              method: 'post',
              data: {modelId: model.id,}
            }).then((data) => {
              done();
              loading.close();
              let newModels = {};
              for (let key in this.models) {
                console.log(this.models[key]) // foo, bar
                if (key != model.id) {
                  newModels[key] = this.models[key];
                }
              }
              this.models = newModels;
              this.$message.success('操作成功');
            }).catch((e) => {
              done()
              loading.close();
              this.$message.error(e);
            });
          } else {
            done();
          }
        }
      })
    },
    onSaveCardClick() {
      if (!this.deck) {
        this.$message.warning('请选择记忆库');
        return;
      }
      if (!this.model) {
        this.$message.warning('请选择模板');
        return;
      }
      this.saveData = {};
      let flds = [];
      this.tpl.forEach((v, k) => {
        //处理文本挖空
        v.value = v.value.replace(/on(click)="if\(this\.style\.color == '.*?'\)\{this\.style\.color = '.*?';\}else\{this\.style\.color = '.*?';\}"/gm, "");
        v.value = v.value.replace(new RegExp("class=\"ak-cover\"", "gm"),
          "class=\"ak-cover\" onclick=\"if(this.style.color == 'rgb(0, 0, 0)'){this.style.color = '#F4EDB3';}else{this.style.color = '#000000';}\"");

        //处理图片挖空
        if (v.value.indexOf('image-cover') > -1) {
          v.value = v.value.replace(new RegExp("<figure style='position:relative'", "gm"), "<figure");
          v.value = v.value.replace(new RegExp("<figure", "gm"), "<figure style='position:relative'");
          if (v.value.indexOf("if(!g.onclick)") === -1) {
            v.value += `<script>
if('undefined'===typeof hide_img_cover)hide_img_cover=$('.ck-back').length>0;
$('figure g').each(function(idx,g){
  if(hide_img_cover)g.style.opacity=0.1;
  if(!g.onclick){g.onclick=function(){g.style.opacity=g.style.opacity==0.1?1:0.1}}
});
<\/script>`;
          }
        }


        flds.push(v.value)
      });
      let str = flds[0].replace(/\r/g, "");
      str = str.replace(/\n/g, "");
      // if (str === '') {
      //   this.$message.warning('第一个字段不能为空');
      //   return;
      // }

      let loading = this.$loading();
      this.saveData.id = this.card.id || 0;
      this.saveData.did = this.deck.id;
      this.saveData.nid = this.card.nid;
      this.saveData.mid = this.model.id;
      this.saveData.flds = JSON.stringify(flds);
      request.request({
        url: '/space.card/saveCard',
        method: 'post',
        data: this.saveData
      }).then(data => {
        this.currentEditorIndex = '';
        loading.close();
        this.$emit('save-success', flds);
        if (!this.saveData.nid) {
          this.tpl.forEach((v, k) => {
            this.editors[k].setData('');
            this.tpl[k].value = '';
          });
        }
        this.hasChange = false;
      }).catch((e) => {
        loading.close();
        console.log(e);
        this.$message.error(e)
      });
    },
    getDeckByDid(decks, did) {
      let deck = null;
      for (let k in decks) {
        if (decks[k].id == did) {
          return decks[k];
        }
        if (decks[k].decks) {
          deck = this.getDeckByDid(decks[k].decks, did);
          if (deck) {
            break;
          }
        }
      }
      return deck;
    },
    initEditor() {
      this.model = this.models[this.card.mid];
      console.log('model', this.model);
      this.deck = this.getDeckByDid(this.decks, this.card.did);
      this.$store.commit('setModelsData', this.model)
      this.$store.commit('setDeckData', this.deck)

      console.log('deck', this.deck);
      console.log('card', this.card);
      this.currentEditorIndex = '';
      this.tpl = [];
      this.model.flds.forEach((v, k) => {
        if (this.card.flds[k] === undefined) {
          this.card.flds[k] = '';
        }

        this.tpl.push({name: v.name, value: this.card.flds[k]});
      });
      this.tpl.forEach((v, k) => {
        if (v.value) {//这里是修复之前编辑器把头尾去掉的bug
          v.value = v.value.replace(/on(click)="if\(this\.style\.color == '.*?'\)\{this\.style\.color = '.*?';\}else\{this\.style\.color = '.*?';\}"/gm, "");
          let end = v.value.indexOf('</p>');
          if (end && v.value.indexOf('<p>') > end) {
            v.value = "<p>" + v.value;
          }

          let lastStar = v.value.lastIndexOf("<p>");
          if (lastStar && v.value.indexOf('</p>') < lastStar) {
            v.value += "</p>";
          }

          if (v.value.indexOf('≯#') > -1 && v.value.indexOf('#≮') > -1) {
            setTimeout(() => {
              this.$message.error('【' + v.name + '】已被加密，可能无法编辑');
            }, k * 400);
          }
        }

        let placeholder = "在此输入内容";
        if (this.model && this.model.id == 1001) {
          if (v.name == '问题') {
            placeholder = "色彩的三原色分别是什么？（示例）"
          }
          if (v.name == '选项') {
            placeholder = "每一行就是一个选项，选项之间用换行区分，无需输入前缀A、B、C系统会自动生成"
          }
          if (v.name == '答案') {
            placeholder = "假如第二个选项是正确答案输入数字2，若有多个选项则使用逗号分隔如1,2,3"
          }
        } else if (this.model && this.model.id == 1002) {
          placeholder = '填空卡制作挖空遮挡后，点击显示答案后自动取消所有遮挡';
        }

        if (this.editors[k]) {
          this.editors[k].setData(v.value);
          setTimeout(() => {
            let ckPlaceHolder = document.getElementsByClassName('ck-placeholder')[k];
            if (ckPlaceHolder) {
              ckPlaceHolder.setAttribute('data-placeholder', placeholder);
            } else {
              console.log("no>>");
            }
          }, 500);
        } else {
          AnkiEditor.create(document.querySelector('#card-edit-' + k), {
            placeholder: placeholder,
            simpleUpload: {
              uploadUrl: 'https://api.ankichinas.com/api/v1/napi/common/uploadImgByAnkimemoryWeb',
              headers: {Authorization: this.$cookies.get('authToken')}
            }
          }).then(editor => {
            editor.setData(v.value);
            this.editors[k] = editor;

            //图标涂鸦编辑器
            editor.imageCover = (selectedElement) => {
              this.doImageCoverElement = selectedElement;
              this.doImageCoverEditor = editor;
              let imageElement = null;
              let hasCover = false;
              if (selectedElement.name === 'imageCover') {
                hasCover = true;
                imageElement = selectedElement.parent;
              } else {
                imageElement = selectedElement;
              }

              this.showImageCoverEditor(imageElement.getAttribute('src'), hasCover ? selectedElement.getAttribute('draw') : '');
            };

            editor.model.document.on('change', () => {
              let newHtml = editor.getData();
              if (newHtml.length > 6) {
                if (newHtml.substr(0, 3).toLowerCase() === '<p>'
                  && newHtml.substr(newHtml.length - 4).toLowerCase() === '</p>'
                  && newHtml.indexOf('</p>') === newHtml.length - 4) {
                  newHtml = newHtml.substr(3, newHtml.length - 7)
                }
              }

              let match = '<p>&nbsp;</p>';
              do {
                let lastIndex = newHtml.lastIndexOf(match);
                if (lastIndex > -1 && lastIndex === newHtml.length - match.length) {
                  newHtml = newHtml.substr(0, newHtml.length - match.length);
                } else {
                  break;
                }
              } while (true);

              if (newHtml !== this.tpl[k].value) {
                this.tpl[k].value = newHtml;
                if (!this.card.id) {
                  this.card.flds[k] = newHtml;
                }
                this.hasChange = true;
                setTimeout(() => {
                  let test = document.getElementsByClassName('ak-cover');
                  for (let i = 0; i < test.length; i++) {
                    // test[i].addEventListener('click', this.onAkCoverClick);
                  }
                }, 10);
              }

            });
            editor.editing.view.document.on('focus', () => {
              this.currentEditorIndex = k;
              let test = document.getElementsByClassName('ak-cover');
              for (let i = 0; i < test.length; i++) {
                test[i].style.color = '#000000';
                // test[i].addEventListener('click', this.onAkCoverClick);
              }
            });
            editor.editing.view.document.on('blur', () => {
              console.log('The Document has blur!');
            });

          }).catch(error => {
            console.error(error);
          });
        }
      })
    },
    showImageCoverEditor(src, svg) {
      this.doImageCoverSrc = src;
      this.doImageCoverSvg = svg;
    },
    cancelImageEdit() {
      this.doImageCoverSrc = '';
    },
    finishImageCover(svg) {
      this.doImageCoverEditor.model.change(writer => {
        let imageElement;
        if (this.doImageCoverElement.name === 'imageCover') {
          imageElement = this.doImageCoverElement.parent;
          writer.remove(this.doImageCoverElement);
          if (svg === '') {
            return;
          }
        } else {
          imageElement = this.doImageCoverElement;
        }

        const newImageCoverElement = writer.createElement('imageCover');
        writer.setAttribute('draw', svg, newImageCoverElement);
        writer.append(newImageCoverElement, imageElement);
      });
      this.doImageCoverSrc = '';
    },
  },
  watch: {
    card() {
      console.log('card change!!', this.card);
      if (this.card && this.card.nid !== undefined) {
        this.hasChange = false;
        this.initEditor();
      }
    }
  },
  created() {
    for (let i = 0; i < 100; i++) {
      this.editors.push(null);
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.options-item {
  z-index: 99999 !important;
}

.del {
  display: none;
}

.model-item:hover .del {
  display: block;
}

.model-item span:hover {
  color: #26b38c;
}
</style>
