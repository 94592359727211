<template>
  <div class="h-fill bg-f">
    <div class="position-absolute z-index-999 w-fill">
      <div class="container">
        <a href="/"><img src="/static/image/logo-h.png" class="w-120 mg-t-10"></a>
      </div>
    </div>
    <div class="justify-content-center align-items-center" style="height: 85%">
      <div class="mg-r-80">
        <img src="/static/image/login-bg.png" width="85%">
      </div>
      <div class="w-400">
        <div v-if="loginForm.bindKey">
          <div class="fs-32 text-strong">需绑定手机号</div>
          <div class="fs-12 c-9 mg-t-10">
            绑定您的Anki记忆卡手机帐号，若未注册过则使用该手机号注册为新用户
          </div>
          <div class="h-80 pd-t-40">
            <div class="position-relative">
              <div class="fs-14 c-9">输入手机号码</div>
              <el-select v-model="loginForm.area" class="area position-absolute l-0 t-22 w-60">
                <el-option v-for="item in areaList" :value="item.code">
                  {{ item.code }} - {{ item.name }}
                </el-option>
              </el-select>
              <input v-model="loginForm.phone" class="input w-fill box pd-l-60 lh-40"/>
            </div>
          </div>
          <div class="h-80">
            <div class="position-relative ">
              <div class="fs-14 c-9">输入验证码</div>
              <a @click="onGetCodeClick" :class="getSMStxt === '获取验证码'?'c-app-green':'c-9'"
                 class="position-absolute z-index-999 r-10 t-30">{{ getSMStxt }}</a>
              <input maxlength="6" v-model="loginForm.code" class="input w-fill lh-40"/>
            </div>
          </div>
          <div class="mg-t-50 mg-b-60">
            <el-button @click="onLoginClick" type="primary" class="w-fill">确认绑定</el-button>
          </div>
        </div>
        <div v-else>
          <div class="fs-32 text-strong">登录Anki记忆卡</div>
          <div class="h-300">
            <div v-if="loginType == 'phone'">
              <div class="h-80 pd-t-40">
                <div class="position-relative">
                  <div class="fs-14 c-9">输入手机号码</div>
                  <el-select v-model="loginForm.area" class="area position-absolute l-0 t-22 w-60">
                    <el-option v-for="item in areaList" :value="item.code">
                      {{ item.code }} - {{ item.name }}
                    </el-option>
                  </el-select>
                  <input v-model="loginForm.phone" class="input w-fill box pd-l-60 lh-40"/>
                </div>
              </div>
              <div class="h-80">
                <div class="position-relative ">
                  <div class="fs-14 c-9">输入验证码</div>
                  <a @click="onGetCodeClick" :class="getSMStxt === '获取验证码'?'c-app-green':'c-9'"
                     class="position-absolute z-index-999 r-10 t-30">{{ getSMStxt }}</a>
                  <input maxlength="6" v-model="loginForm.code" class="input w-fill lh-40"/>
                </div>
              </div>
              <div class="mg-t-50">
                <el-button @click="onLoginClick" type="primary" class="w-fill">登录</el-button>
              </div>
            </div>
            <div v-else-if="loginType == 'wx'" class="justify-content-center">
              <div class="pd-t-30">
                <div v-if="!wxLoginQrCode"
                     class="c-e w-220 h-220 border-1 bdc-e bdr-10 justify-content-center align-items-center">
                  正在加载...
                </div>
                <vue-qr v-else :text="wxLoginQrCode" :size="220"></vue-qr>
                <div class="text-align-center mg-t-20">打开微信扫码登录</div>
              </div>
            </div>
          </div>
          <div class="align-items-center mg-t-20">
            <div
                style="width: 184px;height: 2px;background: linear-gradient(296deg, rgba(242, 242, 242, 1), rgba(255, 255, 255, 1));"></div>
            <div class="w-200 text-align-center c-c fs-12">其他登录方式</div>
            <div
                style="width: 184px;height: 2px;background: linear-gradient(116deg, rgba(242, 242, 242, 1), rgba(255, 255, 255, 1));"></div>
          </div>
          <div class="align-items-center mg-t-20 justify-content-center">
            <div class="w-24 cursor-pointer mg-r-40" @click="loginType = 'phone'" v-if="loginType != 'phone'"><img
                src="../assets/login_phone.png">
            </div>
            <div class="w-24 cursor-pointer mg-r-40" @click="onWechatLoginClick" v-if="loginType != 'wx'"><img
                src="../assets/login_weixin.png"></div>
            <div @click="qqLoginClick" class="w-24 cursor-pointer"><img src="../assets/login_qq.png"></div>
          </div>
          <div class="mg-t-20 text-align-center">
            <el-checkbox class="mg-r-20" v-model="agree"></el-checkbox>
            已阅读并同意<a href="https://other.ankichinas.cn/agreement.html" class="c-app-green cursor-pointer">用户协议</a>和<a class="c-app-green cursor-pointer" href="https://other.ankichinas.cn/privacy.html">隐私政策</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '../libs/api.request'
import storage from '../libs/storage'
import areaList from '../libs/areaList'
import VueQr from 'vue-qr'

export default {
  name: "Login",
  components: {
    VueQr
  },
  data() {
    return {
      loginForm: {code: '', phone: '', bindKey: null, area: 86},
      getSMStxt: '获取验证码',
      loginType: 'phone',
      wxLoginQrCode: '',
      checkCode: '',
      areaList: areaList.areaList,
      agree: false
    }
  },
  watch: {}
  ,
  methods: {
    onWechatLoginClick() {
      if (!this.agree) {
        this.$message.error('请阅读并同意用户协议和隐私政策');
        return;
      }
      this.wxLoginQrCode = '';
      this.checkCode = '';
      this.loginType = 'wx';
      request.request({
        url: '/user/qrLogin',
      }).then(data => {
        this.wxLoginQrCode = data.qrCode
        this.checkCode = data.checkCode
        this.checkQrLogin();
      })
    },
    onGetCodeClick() {
      if (this.loginForm.phone.length < 8) {
        this.$message.error('手机号有误');
        return;
      }

      if (this.getSMStxt !== "获取验证码") {
        return;
      }
      let timer = 60;
      this.getSMStxt = timer + "s 重新获取";
      let interval = setInterval(() => {
        if (timer > 0) {
          timer--;
          this.getSMStxt = timer + "s 重新获取";
        } else {
          this.getSMStxt = "获取验证码";
          clearInterval(interval)
        }
      }, 1000);
      request.request({
        url: "/common/getSmsCode",
        params: {
          phone: this.loginForm.phone,
          area: this.loginForm.area,
          type: 'login'
        }
      }).then((data) => {
        console.log(data);
      }).catch((message) => {
        this.$message.error(message);
      })
    }
    ,
    onLoginClick() {
      if (!this.agree) {
        this.$message.error('请阅读并同意用户协议和隐私政策');
        return;
      }
      let loading = this.$loading({text: ''});
      request.request({url: '/user/loginByPhoneFromAnkiWeb', data: this.loginForm, method: 'post'}).then((data) => {
        loading.close();
        this.$cookies.set('authToken', data.token, 86400 * 90);
        storage.setUserInfo(data.userInfo);
        setTimeout(() => {
          location.reload();
        }, 200);
      }).catch(err => {
        loading.close();
        setTimeout(() => {
          this.$message.error(err.message);
        }, 200);
      })
    }
    ,
    checkQrLogin() {
      if (this.checkCode) {
        request.request({
          url: "/user/checkQrLogin",
          params: {
            checkCode: this.checkCode,
          }
        }).then((data) => {
          if (data.status == 1) {
            this.checkQrLogin()
          } else if (data.status == 2) {
            if (data.token) {
              this.$cookies.set('authToken', data.token, 86400 * 90);
              storage.setUserInfo(data.userInfo);
              setTimeout(() => {
                location.reload();
              }, 100)
            } else {
              this.loginForm.bindKey = JSON.stringify(data)
            }
          } else {
            alert('二维码已过期，请刷新页面');
          }


        }).catch((message) => {
          setTimeout(() => {
            this.checkQrLogin()
          }, 2000)
        })
      }
    }
    ,
    qqLoginClick() {
      if (!this.agree) {
        this.$message.error('请阅读并同意用户协议和隐私政策');
        return;
      }
      let redirectUri = encodeURIComponent("https://ankimemory.com/qqcb");
      location.href = "https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=102035878&redirect_uri=" + redirectUri + "&state=1"
    }
  }
  ,
  mounted() {
    if (this.$parent.loginUser) {
      this.$router.replace('/space');
      return;
    }

    if (this.$route.path === '/qqcb') {
      let cbData = this.$route.query;
      if (cbData) {
        let loading = this.$loading({text: '登录中...'});
        request.request({
          url: "/user/qqLogin",
          params: cbData
        }).then((data) => {
          sessionStorage.removeItem("qqcb");
          loading.close()
          if (data.token) {
            this.$cookies.set('authToken', data.token, 86400 * 90);
            storage.setUserInfo(data.userInfo);
            setTimeout(() => {
              location.reload();
            }, 100)
          } else {
            this.loginForm.bindKey = JSON.stringify(data)
          }
        }).catch((e) => {
          this.$message.error(e)
          sessionStorage.removeItem("qqcb");
          loading.close()
        })
      }
    }

    this.$parent.hideNavigateBar();
    setTimeout(() => {
      // QC.Login()
    }, 500)
  }
}
</script>

<style scoped>

.input {
  outline: none;
  border: none;
  border-bottom: 1px solid #999999
}

.input:focus {
  border-bottom: 2px solid #00b0b2;
}

.area >>> .el-input__inner {
  border: none !important;
  padding: 0;
}
</style>
