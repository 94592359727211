<template>
  <div>
    <div class="container justify-content-space-between" style="overflow: auto; ">
      <div class="deck-menu w-300">
        <!--<div class="position-fixed w-300 b-20 t-80 hide-scrollbar bdr-10" style="overflow-y: scroll">-->
        <div class="w-300 bdr-10">
          <div class="bg-f pd-10 bdr-10">
            <div class="fs-16 text-strong">今日待学</div>
            <div class="align-items-center pd-t-20 pd-b-20 mg-t-20 mg-b-20 bdr-10">
              <div class="flex-1 text-align-center">
                <div class=" fs-28">{{ newCard[0] }}</div>
                <div class="c-8 fs-12">新卡</div>
              </div>
              <div class="w-1 h-20 bg-c"></div>
              <div class="flex-1 text-align-center">
                <div class=" fs-28">{{ reviewCard[0] + learningCard + relearningCard }}</div>
                <div class="c-8 fs-12">待复习</div>
              </div>
              <div class="w-1 h-20 bg-c"></div>
              <div class="flex-1 text-align-center">
                <div class=" fs-28">
                  {{ Math.ceil((newCard[0] + reviewCard[0] + learningCard) / 6) }}
                </div>
                <div class="c-8 fs-12">预计耗时(分)</div>
              </div>
            </div>
            <div class="justify-content-space-between fs-15 mg-t-20">
              <div class="text-strong">我的资源</div>
              <div><img src="/static/image/side_bar_add_folder.png" style="cursor: pointer" class="mg-r-10 w-20"
                        @click="$parent.onCreateDeckClick"></div>
            </div>
            <div v-bind:key="deck.id" v-for="deck in decks"
                 class="deck-item border-1 pd-l-10 pd-r-10 pd-t-15 pd-b-15 mg-t-10 bdr-10 bdc-f3 cursor-pointer fs-14"
                 @click="onDeckClick(deck)">
              <div class="display-flex">
                <div class="flex-1 fs-15 text-strong">
                  {{ deck.name }}
                </div>
                <div class="w-32 text-align-right">
                  <el-dropdown :show-timeout="0">
                    <div class="display-flex pd-l-15 pd-t-8 pd-b-10">
                      <div class="h-3 w-3 bdr-circular bg-app-green mg-r-2"></div>
                      <div class="h-3 w-3 bdr-circular bg-app-green mg-r-2"></div>
                      <div class="h-3 w-3 bdr-circular bg-app-green mg-r-2"></div>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="onEditDeckClick(deck)">编辑</el-dropdown-item>
                      <el-dropdown-item @click.native="onDeleteDeckClick(deck)">删除</el-dropdown-item>
                      <el-dropdown-item @click.native="onMoveDeckClick(deck)">移动</el-dropdown-item>
                      <el-dropdown-item @click.native="onExportDeckClick(deck)">导出</el-dropdown-item>
                      <!--<el-dropdown-item>导出</el-dropdown-item>-->
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <div class="c-8 fs-12 mg-t-10">
                <span>今日新卡 <span class="c-3">{{ deck.newCard[0] }}</span></span>
                <span class="mg-l-20">待复习
                  <span v-if="deck.totalCard != '...'"
                    class="c-3">{{
                    parseInt(deck.reviewCard[0]) + parseInt(deck.learningCard) + parseInt(deck.relearningCard)
                  }}</span>
                </span>
              </div>
              <div v-if="deck.totalCard == 0 || deck.newCard[1] > 0">
                <el-progress v-if="deck.totalCard > 0" color="#a5d5cb" define-back-color="#eceef4" class="mg-t-6"
                             :show-text="false" :stroke-width="4"
                             :percentage="deck.totalCard > 0 ?parseInt((deck.totalCard - deck.newCard[1]) / deck.totalCard * 100):0"></el-progress>
                <div class="fs-12 mg-t-6 justify-content-space-between c-9">
                  <div v-if="deck.totalCard > 0">已学 {{
                    parseInt((deck.totalCard - deck.newCard[1]) / deck.totalCard *
                    100)
                    }}%
                  </div>
                  <div v-else>已学 0%</div>
                  <div>{{ deck.totalCard - deck.newCard[1] }} / {{ deck.totalCard }}</div>
                </div>
              </div>
              <div class="handled" v-else>
                <el-progress class="mg-t-10" :show-text="false" :stroke-width="4"
                             :percentage=" deck.totalCard>0?parseInt(deck.handledCard / deck.totalCard * 100):0"></el-progress>
                <div v-if="deck.totalCard != '...'" class="fs-12 mg-t-10 justify-content-space-between c-9">
                  <div>已掌握 {{ parseInt(deck.handledCard / deck.totalCard * 100) }}%
                  </div>
                  <div>{{ deck.handledCard }} / {{ deck.totalCard }}</div>
                </div>
                <div v-else class="fs-12 mg-t-10 justify-content-space-between c-9">
                  <div></div>
                  <div>...</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pd-b-100 bg-f4f w-685 ">
        <div class="bg-f bdr-10 pd-l-10 pd-r-10 pd-b-15">
          <div v-for="v,k in editors" class="" v-show="tpl[k]" :key="k"
               :class="currentEditorIndex === k?'current-editor':'not-current-editor'">
            <div class="display-flex" style="flex-wrap: wrap;">
              <div v-if="tpl[k]" :class="currentEditorIndex === k ?'display-none':''"
                   class="mg-b-5 fs-16 mg-l-12 lh-40 text-strong" style="white-space: nowrap;">
                {{ tpl[k].name }}
              </div>
            </div>
            <div :id="'edit-' + k"></div>
          </div>
          <div class="pd-t-10">
            <el-alert
                v-if="notice"
                :title="notice"
                close-text="我知道了"
                @close="onNoticeClose"
                type="info">
            </el-alert>
          </div>
          <div class="align-items-center justify-content-space-between mg-t-10">
            <div class="align-items-center c-9">
              <a class="c-app-green" @click="onSelectDeckClick('forAddCard')">
                <span class="" v-if="deckForEditCard"><i
                    class="el-icon-caret-bottom"></i>{{ deckForEditCard.simpleName }}</span>
                <span v-else><i class="el-icon-caret-bottom"></i>选择记忆库</span>
              </a>
              <a @click="onSelectModelClick" class="mg-l-20 c-app-green">
                <span class="" v-if="selectModel"><i
                    class="el-icon-caret-bottom"></i>{{ selectModel.name }}</span>
                <span v-else><i class="el-icon-caret-bottom"></i>选择模板</span>
              </a>
              <a @click="onMakeClick" class="mg-l-30 c-app-green">
                批量制卡
              </a>
            </div>
            <div class="align-items-center">
              <!--<a class="mg-r-30" @click="onFullScreenClick"><i class="el-icon-full-screen mg-r-4"></i>全屏</a>-->
              <el-button @click="onSaveCardClick" type="primary" size="small" class="w-60 bdr-8 pd-2">
                <i class="el-icon-check text-strong fs-18"></i>
              </el-button>
            </div>
          </div>
        </div>
        <div class="bg-f bdr-10 pd-12 mg-t-10">
          <div id="div_sel"
               :class="divSelTop?'border-1 bdc-f3 bdr-10 pd-12 position-fixed t-60 w-635 bg-f z-index-888':''">
            <div class="align-items-center justify-content-space-between">
              <div class="fs-16 text-strong">我的卡牌</div>
              <el-input size="small" v-model="searchForm.keyword" class="w-250 bdr-20 bg-f3f mg-l-15" placeholder="搜索"
                        @keyup.enter.native="onSearchCard">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
              </el-input>
            </div>
            <div class="justify-content-space-between mg-t-15 align-items-center">
              <div class="tabs">
                <div class="mg-r-20 mg-t-5 active display-inline-block">
                  <el-dropdown @command="onSelectTypeClick">
                  <span class="c-2">
                    <template v-if="searchForm.type == 'all'">全部</template>
                    <template v-if="searchForm.type == 'lrn'">学习中</template>
                    <template v-if="searchForm.type == 'new'">未学习</template>
                    <template v-if="searchForm.type == 'handled'">已掌握</template>
                    <template v-if="searchForm.type == 'hard'">近期难点</template>
                    <template v-if="searchForm.type == 'hard2'">疑难知识</template>
                    <template v-if="searchForm.type == 'suspend'">已暂停</template>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="all">全部</el-dropdown-item>
                      <el-dropdown-item command="lrn">学习中</el-dropdown-item>
                      <el-dropdown-item command="new">未学习</el-dropdown-item>
                      <el-dropdown-item command="handled">已掌握</el-dropdown-item>
                      <el-dropdown-item command="hard">近期难点</el-dropdown-item>
                      <el-dropdown-item command="hard2">疑难知识</el-dropdown-item>
                      <el-dropdown-item command="suspend">已暂停</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="mg-r-20 mg-t-5 active display-inline-block">
                  <el-dropdown @command="colorChange">
                  <span class="c-2">
                    <template v-if="searchForm.flag == 0">全部颜色</template>
                    <template v-if="searchForm.flag == 1">无色</template>
                    <template v-if="searchForm.flag == 2">红色</template>
                    <template v-if="searchForm.flag == 3">黄色</template>
                    <template v-if="searchForm.flag == 4">绿色</template>
                    <template v-if="searchForm.flag == 5">蓝色</template>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="0">全部颜色</el-dropdown-item>
                      <el-dropdown-item command="1">无色</el-dropdown-item>
                      <el-dropdown-item command="2">红色</el-dropdown-item>
                      <el-dropdown-item command="3">黄色</el-dropdown-item>
                      <el-dropdown-item command="4">绿色</el-dropdown-item>
                      <el-dropdown-item command="5">蓝色</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="mg-r-20 mg-t-5 active display-inline-block">
                  <el-dropdown @command="onSelectSortTypeClick">
                  <span class="c-2">
                    <template v-if="searchForm.sort_type == 'id'">按创建时间排序</template>
                    <template v-if="searchForm.sort_type == 'sfld'">按名称排序</template>
                    <template v-if="searchForm.sort_type == 'lapses'">按忘记次数排序</template>
                    <template v-if="searchForm.sort_type == 'reps'">按复习次数排序</template>
                    <template v-if="searchForm.sort_type == 'due'">按到期排序时间</template>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="id">按创建时间排序</el-dropdown-item>
                      <el-dropdown-item command="sfld">按名称排序</el-dropdown-item>
                      <el-dropdown-item command="lapses">按忘记次数排序</el-dropdown-item>
                      <el-dropdown-item command="reps">按复习次数排序</el-dropdown-item>
                      <el-dropdown-item command="due">按到期时间</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="mg-t-5 display-inline-block">
                  <el-popover
                      placement="top"
                      width="400"
                      trigger="hover"
                      v-model="showFilter"
                  >
                    <div>
                      <div class="text-align-center">
                        <div class="justify-content-space-between pd-t-10 pd-b-10"
                             style="border-bottom: 1px solid #f3f3f3;">
                          <div class="lh-36" style="width: 14%">创建时间</div>
                          <el-slider style="width: 65%" class="mg-r-10 mg-l-10"
                                     v-model="searchForm.create_time" :show-tooltip="false" :max="365"></el-slider>
                          <div class="lh-36" style="width: 15%">
                            <span v-if="searchForm.create_time==365">不限</span>
                            <span v-else>近{{ searchForm.create_time }}天</span>
                          </div>
                        </div>
                        <div class="justify-content-space-between pd-t-10 pd-b-10"
                             style="border-bottom: 1px solid #f3f3f3;">
                          <div class="lh-36" style="width: 14%">答题时间</div>
                          <el-slider style="width: 65%" class="mg-r-10 mg-l-10"
                                     v-model="searchForm.answer_time" :show-tooltip="false" :max="30"></el-slider>
                          <div class="lh-36" style="width: 15%">
                            <span v-if="searchForm.answer_time==30">不限</span>
                            <span v-else>近{{ searchForm.answer_time }}天</span>
                          </div>
                        </div>
                        <div class="justify-content-space-between pd-t-10 pd-b-10"
                             style="border-bottom: 1px solid #f3f3f3;">
                          <div class="lh-36" style="width: 14%">下次复习</div>
                          <el-slider style="width: 65%" class="mg-r-10 mg-l-10"
                                     range
                                     :show-tooltip="false"
                                     v-model="searchForm.next_reps_time" :max="100"></el-slider>
                          <div class="lh-36" style="width: 15%">
                        <span v-if="searchForm.next_reps_time[1]==100">
                          {{ searchForm.next_reps_time[0] }}-不限
                        </span>
                            <span v-else>
                          {{ searchForm.next_reps_time[0] }}-{{ searchForm.next_reps_time[1] }}天
                        </span>
                          </div>
                        </div>
                        <div class="justify-content-space-between pd-t-10 pd-b-10"
                             style="border-bottom: 1px solid #f3f3f3;">
                          <div class="lh-36" style="width: 14%">复习次数</div>
                          <el-slider style="width: 65%" class="mg-r-10 mg-l-10"
                                     range
                                     :show-tooltip="false"
                                     v-model="searchForm.reps" :max="20"></el-slider>
                          <div class="lh-36" style="width: 15%">
                        <span v-if="searchForm.reps[1]==20">
                          {{ searchForm.reps[0] }}-不限
                        </span>
                            <span v-else>
                          {{ searchForm.reps[0] }}-{{ searchForm.reps[1] }}次
                        </span>
                          </div>
                        </div>
                        <div class="justify-content-space-between pd-t-10 pd-b-10">
                          <div class="lh-36" style="width: 14%">忘记次数</div>
                          <el-slider style="width: 65%" class="mg-r-10 mg-l-10"
                                     range
                                     :show-tooltip="false"
                                     v-model="searchForm.lapses" :max="20"></el-slider>
                          <div class="lh-36" style="width: 15%">
                        <span v-if="searchForm.lapses[1]==20">
                          {{ searchForm.lapses[0] }}-不限
                        </span>
                            <span v-else>
                          {{ searchForm.lapses[0] }}-{{ searchForm.lapses[1] }}次
                        </span>
                          </div>
                        </div>
                        <div class="justify-content-space-between pd-t-10 pd-b-10">
                          <el-button style="width: 30%" size="medium" @click="onResetFilterClick" class="bdr-8 fs-12">重置
                          </el-button>
                          <el-button style="width: 65%" type="primary" @click="onFilterClick"
                                     size="medium" class="bdr-8 fs-12 mg-r-5">
                            筛选
                          </el-button>
                        </div>
                      </div>

                    </div>
                    <!--                  <el-button type="primary" size="mini" class="bdr-8 fs-12" slot="reference">
                                        更多筛选
                                      </el-button>-->
                    <div class="active" slot="reference">更多筛选</div>
                  </el-popover>
                </div>
              </div>
              <div>
              </div>
            </div>
            <div class="justify-content-space-between mg-t-10 c-9">
              <div><span class="c-8 ">筛选出{{ cardListCount }}张卡牌</span></div>
              <div class="align-items-center">
                <div class="mg-r-20">
                  <el-checkbox class="c-9" @change="changeShowImg" v-model="showImg">显示图片</el-checkbox>
                </div>
                <div class="mg-r-20">
                  <el-checkbox class="c-9" @change="changeShowContent" v-model="showContent">显示内容</el-checkbox>
                </div>
                <div class="cursor-pointer" @click="onSortClick">
                  <template v-if="searchForm.sort == 1"><i class="el-icon-sort-down"></i>升序</template>
                  <template v-else><i class="el-icon-sort-up"></i>降序</template>
                </div>
                <div @click="onBatchEditClick" class="cursor-pointer mg-l-20">
                  {{ batchEditing ? '取消操作' : '批量操作' }}
                </div>
              </div>
            </div>
            <div v-if="batchEditing" class="justify-content-space-between align-items-center mg-t-10 c-9">
              <div>
                <el-checkbox class="c-9" style="font-weight: normal" v-model="batchAllIsChecked"
                             @change="onSelectAllChange">已选 {{
                  batchAllIsChecked ?
                  cardListCount - withoutBatchOptionIds.length : batchOptionIds.length
                  }}
                </el-checkbox>
              </div>
              <div class="align-items-center c-app-green">
                <div @click="onBatchDeleteClick" class="cursor-pointer mg-r-20 pd-t-5 pd-b-5">删除</div>
                <div @click="onBatchSuspendClick(-1)" v-if="searchForm.type != 'suspend'"
                     class="cursor-pointer mg-r-20 pd-t-5 pd-b-5">暂停
                </div>
                <div @click="onBatchSuspendClick(1)" v-else class="cursor-pointer mg-r-20 pd-t-5 pd-b-5">取消暂停</div>
                <div @click="onBatchMoveClick" class="cursor-pointer pd-t-5 pd-b-5 mg-r-20">移动</div>
                <div @click="onBatchChangeFlagClick" class="cursor-pointer pd-t-5 pd-b-5 mg-r-20">标记</div>
                <div @click="onBatchChangeModelClick" class="cursor-pointer pd-t-5 pd-b-5">更换模版</div>
              </div>
            </div>
          </div>
          <div id="div_cont">
            <div class="justify-content-space-between flex-wrap">
              <div v-bind:key="card.id" v-for="(card,index) in cardList" class="w-325 tooltip">
                <div
                    class="position-relative mg-t-15 border-1 bdc-f3 bdr-10 pd-12 fs-14 cursor-pointer column justify-content-space-between"
                    :style="'min-height: '+(showContent || showImg ?165:100)+'px'"
                    @click="onPreviewCardClick($event,card,index)">
                  <div v-if="batchEditing" class="pd-t-4 pd-b-4 pd-r-5 sfld text-ellipsis-1">
                    <el-checkbox v-model="card.isChecked" class="c-9 " style="font-weight: normal;"
                                 @change="onCardSelectChange(card)"
                                 @click.native="stopDefault($event)"
                    >
                      <div class="display-flex text-strong c-1 w-280"
                           :class="showContent?'text-ellipsis-2 h-44':'text-ellipsis-3 h-66'">
                        {{ formatSfld(card.sfld) }}
                      </div>
                    </el-checkbox>
                  </div>
                  <div v-if="!batchEditing" class="position-absolute t-0 r-5 fs-16 c-app-green tooltiptext"
                       @click.stop="onEditCardClick($event,card,index)">
                    <i class="el-icon-edit sfld" title="编辑"></i>
                  </div>
                  <div>
                    <div class="lh-22 c-2 fs-15" :class="batchEditing?'pd-l-25':''"
                         style="word-break:break-all;">

                      <div v-if="!batchEditing" class="cursor-pointer w-280 mg-b-5 text-strong"
                           :class="showContent?'text-ellipsis-2 h-44':'text-ellipsis-3 h-66'"
                           v-html="card.sfld">
                      </div>
                      <div v-if="showContent && card.fldsText" class="fs-13 c-9"
                           :class="showImg && card.img?'text-ellipsis-1':'text-ellipsis-4'"
                           v-html="card.fldsText"
                      >
                      </div>
                      <div v-if="showImg">
                        <div v-if="card.img" class="mg-t-5">
                          <el-popover trigger="hover" v-for="(img,i) in card.img">
                            <el-image :src="img" style="height: 300px;"></el-image>
                            <el-image
                                style="width:65px; height: 65px; margin-right: 5px;"
                                :src="img"
                                fit="contain"
                                slot="reference"
                            ></el-image>
                          </el-popover>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fs-12">
                    <div class="position-relative justify-content-space-between">
                      <div class="c-9 align-items-center mg-t-2">
                        <el-tooltip :visible-arrow="false" :content="card.deckName"
                                    placement="bottom-start">
                          <div class="c-9 fs-12 cursor-pointer text-ellipsis-1" style="max-width: 100px">
                            {{ card.deckSimpleName }}
                          </div>
                        </el-tooltip>
                      </div>
                      <div class="c-9 align-items-center">
                        <div class="mg-r-6">复习 <span class="c-2">{{ card.reps }}</span></div>
                        <div class="mg-r-6">忘记 <span class="c-2">{{ card.lapses }}</span></div>
                        <div class="mg-r-6" v-if="card.queue == -1">已暂停</div>
                        <div class="mg-r-6" v-else-if="card.queue == 0">新卡</div>
                        <div class="mg-r-6" v-else-if="card.queue == 1">学习中</div>
                        <div class="mg-r-6" v-else-if="card.afterDue !== undefined">{{
                          revTxt(card.afterDue)
                          }}
                        </div>
                        <div class="cursor-pointer mg-r-5">
                          <i @click.stop="onMarkedClick(card,0)" v-if="card.tags.indexOf('marked') > -1"
                             class="el-icon-star-on fs-20 c-app-yellow"></i>
                          <i @click.stop="onMarkedClick(card,1)" v-else class="el-icon-star-on fs-20"></i>
                        </div>
                        <div @click.stop="onSelectFlagClick(index)" class="mg-r-5">
                          <i slot="reference" class="el-icon-s-flag fs-16 cursor-pointer"
                             :style="'color:'+util.getFlagColor(card.flags)"></i>
                        </div>
                        <div v-if="batchEditing" class="">
                          <i @click.stop="onDeleteCardClick(card)" class="el-icon-delete-solid fs-16"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pd-20" v-if="this.loadMoreIng">
            <div class="text-align-center c-8">加载中...</div>
          </div>
        </div>
      </div>
      <!--<div class="">-->
        <!--<div class="w-250 bg-f bdr-10 pd-l-10 pd-r-10 pd-b-15 pd-t-10">-->
          <!--<div class="justify-content-space-between fs-16 text-strong mg-b-20">-->
            <!--<div>推荐资源</div>-->
            <!--<div @click="this.$parent.onSetUserLabelClick"><i class="el-icon-s-tools fs-20 c-a cursor-pointer"></i>-->
            <!--</div>-->
          <!--</div>-->
          <!--<div v-if="!util.isEmpty(recommendCardList)">-->
            <!--<div v-for="(item,index) in recommendCardList" class="pd-b-10 mg-t-10 bdr-10 border-1 bdc-f3">-->
              <!--<card-item class="bg-f bdr-6" :card="item"></card-item>-->
            <!--</div>-->
          <!--</div>-->
          <!--<div v-else>-->
            <!--<div class="text-align-center mg-t-50 mg-b-50 c-7 fs-14">{{ recommendText }}</div>-->
          <!--</div>-->
          <!--<div class="text-align-center mg-t-10">-->
            <!--<a class="" href="https://file.ankichinas.cn/" style="color: #28bea0" target="_blank">查看更多</a>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
    </div>
    <el-drawer
        title="选择记忆库"
        :visible.sync="showSelectDeckDrawer"
        :size="400"
        direction="ltr">
      <div class="pd-20" style="overflow-y: scroll">
        <select-deck v-on:on-item-select="onDeckSelect" :decks="decks" :open="true"></select-deck>
      </div>
    </el-drawer>
    <el-drawer
        title="切换模板"
        :visible.sync="showSelectModelDrawer"
        :size="400"
        direction="ltr">
      <div class="pd-20">
        <div v-for="model in models" :key="model.id"
             class="align-items-center mg-b-20 pd-20 bg-f4f bdr-10 model-item cursor-pointer">
          <span class="flex-1 " @click="onModelSelect(model)"> {{ model.name }}
          <i v-if="model.usedCount !== undefined" class="fs-12 c-9">{{ model.usedCount }}卡牌</i>
          </span>
          <span v-if="!model.gf" class="del" @click="onModelDelete(model)">删除</span>
        </div>
      </div>
    </el-drawer>
    <card-editor v-show="fullScreenEdit" :models="models" :decks="decks" :card="editCard"
                 v-on:save-success="onSaveSuccess"
                 v-on:close-click="onEditorCloseClick"
                 @toPreview="toPreview"/>
    <!-- 预览 -->
    <card-viewer v-if="isPreview" :did="currentCard.did + ''"
                 @previewClose="previewClose"
                 @previewEditClick="previewEditClick"
                 :isPreview="isPreview"
                 :cardList="cardList"
                 :currentCardIndex="currentCardIndex"
                 :currentEditCard="currentEditCard"
    ></card-viewer>
    <el-dialog :visible.sync="showFlagOptions" width="400px" top="40vh">
      <div slot="title">标记</div>
      <div class="justify-content-center">
        <div @click.stop="onFlagClick(0)" class="pd-5">
          <i slot="reference" class="el-icon-s-flag fs-32 cursor-pointer c-9"></i>
        </div>
        <div @click.stop="onFlagClick(1)" class="pd-5">
          <i slot="reference" class="el-icon-s-flag fs-32 mg-l-25 cursor-pointer c-app-red "></i>
        </div>
        <div @click.stop="onFlagClick(2)" class="pd-5">
          <i slot="reference" class="el-icon-s-flag fs-32 mg-l-25 cursor-pointer c-app-yellow"></i>
        </div>
        <div @click.stop="onFlagClick(3)" class="pd-5">
          <i slot="reference" class="el-icon-s-flag fs-32 mg-l-25 cursor-pointer c-app-green"></i>
        </div>
        <div @click.stop="onFlagClick(4)" class="pd-5">
          <i slot="reference" class="el-icon-s-flag fs-32 mg-l-25 cursor-pointer c-app-blue"></i>
        </div>
      </div>
    </el-dialog>

    <export-card v-if="isExportCard" :exportDeck="exportDeck"
                 :isExportCard="isExportCard"
                 @closeExportCard="closeExportCard">
    </export-card>
    <select-drawer
        @changeDrawer="changeDrawer"
        @deckSelect="deckSelect"
        :showDrawer="showDrawer"
        title="选择记忆库"
    >
    </select-drawer>
    <image-cover-editor v-if="doImageCoverSrc" :doImageCoverSrc="doImageCoverSrc" :doImageCoverSvg="doImageCoverSvg"
                        @cancelImageEdit="cancelImageEdit" @finishImageCover="finishImageCover">
    </image-cover-editor>
    <el-backtop class="fs-14">顶部</el-backtop>
  </div>
</template>

<script>

import SelectDeck from '../components/SelectDeck'
import request from '../libs/api.request'
import util from '../libs/util'
import CardEditor from '../components/CardEditor'
import CardViewer from '../components/CardViewer.vue'
import ExportCard from '../components/ExportCard.vue'
import SelectDrawer from '../components/select-drawer/SelectDrawer.vue'
import ImageCoverEditor from "@/components/edit-plugin/ImageCoverEditor";
import CardItem from "@/components/CardItem.vue";

export default {
  name: 'Index',
  components: {
    ImageCoverEditor,
    SelectDeck, CardEditor,
    CardViewer,
    ExportCard,
    SelectDrawer,
    CardItem
  },
  data() {
    return {
      decks: {},
      util: util,
      models: {},
      showFilter: false,
      hasGetModels: false,
      newCard: [0, 0],//今日新卡，全部新卡
      reviewCard: [0, 0],//今日复习卡，全部复习卡
      learningCard: 0,//学习中
      relearningCard: 0,//重新学习
      totalCard: 25,//总数
      currentEditorIndex: '',
      tpl: [],
      editTpl: [],
      tplId: 1,
      editors: [],
      editCard: {},//正在编辑的卡牌
      showSelectDeckDrawer: false,
      showSelectModelDrawer: false,
      deckForEditCard: null,//增加卡牌时选择的记忆库
      deckForSelectCard: null,//筛选卡牌时选择的记忆库,
      selectModel: null,
      selectType: '',
      cardList: [],
      recommendCardList: [],
      recommendText: '',
      cardListCount: 0,//筛选出来的卡牌数量
      searchForm: {
        page: 1,
        size: 10,
        did: '',
        type: 'all',
        keyword: '',
        flag: 0,
        sort: parseInt(localStorage.getItem('cardListSort') || 1),
        sort_type: 'id',
        create_time: 365,
        answer_time: 30,
        next_reps_time: [0, 100],
        reps: [0, 20],
        lapses: [0, 20],
      },
      showImg: localStorage.getItem('showImg') == 1 ? true : false,
      showContent: localStorage.getItem('showContent') == 1 ? true : false,
      loadMoreIng: false,
      noMore: false,
      fullScreenEdit: false,//是否全屏编辑
      batchEditing: false,//批量选择中
      batchOptionIds: [],//批量选中的卡牌id,
      withoutBatchOptionIds: [],//批量选中时排除的卡牌,
      batchAllIsChecked: false,//是否全选
      study: '',
      isPreview: false,
      currentCardIndex: 0,//当前点击的卡牌的索引
      currentCard: {},
      selectFlagCardIndex: -1,
      showFlagOptions: false,
      editCardOrPrevCardIndex: '',
      isExportCard: false,
      exportDeck: {},
      showDrawer: false,
      moveDeck: {},
      currentEditCard: {},
      notice: "",
      doImageCoverElement: null,
      doImageCoverEditor: null,
      doImageCoverSrc: '',
      doImageCoverSvg: '',
      divSelTop: false,
      divDeskTop: false,
    }
  },
  methods: {
    onResetFilterClick() {
      this.searchForm.create_time = 365;
      this.searchForm.answer_time = 30;
      this.searchForm.next_reps_time = [0, 100];
      this.searchForm.reps = [0, 20];
      this.searchForm.lapses = [0, 20];
    },
    onFilterClick() {
      this.searchForm.page = 1;
      this.getCardList();
    },
    changeShowContent(val) {
      console.log(val);
      localStorage.setItem('showContent', val ? 1 : 0);
    },
    changeShowImg(val) {
      console.log(val);
      localStorage.setItem('showImg', val ? 1 : 0);
    },
    onNoticeClose() {
      localStorage.setItem("disableNotice", 1);
      this.notice = '';
    },
    onMoveDeckClick(deck) {
      this.moveDeck = deck
      this.showDrawer = true
    },
    changeDrawer(value) {
      this.showDrawer = value
    },
    deckSelect(deck) {
      console.log(deck);
      request.request({
        url: '/space.deck/move',
        method: 'post',
        data: {
          did: this.moveDeck.id,
          toDid: deck.id
        }
      }).then(res => {
        this.$message({
          type: 'success',
          message: '移动成功！'
        })
        setTimeout(() => {
          this.$router.go(0)
        }, 500);
      }).catch(err => {
        this.$message(err)
      })
    },
    onMakeClick() {
      if (!this.$parent.loginUser.vip || (this.$parent.loginUser.vip && !this.$parent.loginUser.vip.vipAvailable)) {
        let key = 'batchCard';
        let useCount = localStorage.getItem(key) || 0;
        useCount++;
        localStorage.setItem(key, useCount);
        if (!this.$parent.loginUser.nonmember.batchCard) {
          if (useCount <= 2) {
            this.$confirm('批量制卡功能为VIP超级学霸功能，开通会员后无限制使用🌹', '提示', {
              confirmButtonText: '先试用一下',
              cancelButtonText: '立即开通',
              showClose: false,
              closeOnClickModal: false,
            }).then(() => {

            }).catch(() => {
              this.$parent.onVipShowClick();
            });
          } else {
            this.$alert('批量制卡功能试用次数已到达上限，需开通会员才可使用哦🌹', '提示', {
              confirmButtonText: '确定',
              callback: () => {
                this.$parent.onVipShowClick();
              }
            });
            return;
          }
        }
      }
      this.$router.push({path: '/make'})
    },
    onSelectFlagClick(index) {
      this.showFlagOptions = true;
      this.selectFlagCardIndex = index;
    },
    stopDefault(e) {
      e.stopPropagation()
    },
    toPreview(card) {
      this.fullScreenEdit = false
      this.onPreviewCardClick(null, card, this.editCardOrPrevCardIndex)
    },
    previewClose() {
      this.isPreview = false
    },
    previewEditClick(card) {
      this.fullScreenEdit = true
      this.editCard = card
    },

    // 进入预览
    onPreviewCardClick(e, card, index) {
      this.currentCard = card
      this.currentCardIndex = index
      this.isPreview = true
      this.onEditCardClick(null, card, index)
    },
    onEditCardClickFromViewer(card) {
      console.log("onEditCardClickFromViewer");
      this.onEditCardClick(null, card);
    },
    //---------
    onBatchDeleteClick() {
      let count = 0;
      if (this.batchAllIsChecked) {
        if (this.withoutBatchOptionIds.length == this.cardListCount) {
          this.$message.info('请选择卡牌');
          return;
        }
        count = this.cardListCount - this.withoutBatchOptionIds.length;
      } else {
        if (this.batchOptionIds.length === 0) {
          this.$message.info('请选择卡牌');
          return;
        }
        count = this.batchOptionIds.length;
      }
      this.$confirm('已选' + count + '张卡牌将被删除，是否继续?', '删除卡牌', {
        confirmButtonText: '确认删除',
        confirmButtonClass: 'bg-app-red bdc-app-red',
        cancelButtonText: '取消',
        type: 'error',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            done();
            let loading = this.$loading('正在删除卡牌');
            request.request({
              url: '/space.card/batchDeleteCard',
              method: 'post',
              data: {
                checkedAll: this.batchAllIsChecked ? 'yes' : 'no',
                batchOptionIds: JSON.stringify(this.batchOptionIds),
                withoutBatchOptionIds: JSON.stringify(this.withoutBatchOptionIds),
                searchForm: JSON.stringify(this.searchForm),
                cardListCount: this.cardListCount
              }
            }).then((data) => {
              loading.close();
              this.$message.success('已删除');
              this.searchForm.page = 1;
              this.getCardList();
            }).catch((e) => {
              loading.close();
              this.$message.error(e);
            });
          } else {
            done();
          }
        }
      })

    },
    onBatchSuspendClick(type) {
      if (type === -1) {
        type = '暂停';
      } else {
        type = '取消暂停';
      }
      let count = 0;
      if (this.batchAllIsChecked) {
        if (this.withoutBatchOptionIds.length == this.cardListCount) {
          this.$message.info('请选择卡牌');
          return;
        }

        count = this.cardListCount - this.withoutBatchOptionIds.length;
      } else {
        if (this.batchOptionIds.length === 0) {
          this.$message.info('请选择卡牌');
          return;
        }

        count = this.batchOptionIds.length;
      }


      this.$confirm('已选' + count + '张卡牌将被' + type + '，是否继续?', type + '卡牌', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            done();
            let loading = this.$loading('处理中');
            request.request({
              url: '/space.card/batchSuspendCard',
              method: 'post',
              data: {
                checkedAll: this.batchAllIsChecked ? 'yes' : 'no',
                batchOptionIds: JSON.stringify(this.batchOptionIds),
                withoutBatchOptionIds: JSON.stringify(this.withoutBatchOptionIds),
                searchForm: JSON.stringify(this.searchForm),
                cardListCount: this.cardListCount
              }
            }).then((data) => {
              loading.close();
              this.$message.success('操作成功');
              this.searchForm.page = 1;
              this.getCardList();
            }).catch((e) => {
              loading.close();
              this.$message.error(e);
            });
          } else {
            done();
          }
        }
      })
    },
    onBatchMoveClick() {
      if (this.batchAllIsChecked) {
        if (this.withoutBatchOptionIds.length == this.cardListCount) {
          this.$message.info('请选择卡牌');
          return;
        }
      } else {
        if (this.batchOptionIds.length === 0) {
          this.$message.info('请选择卡牌');
          return;
        }
      }
      this.onSelectDeckClick('forMove');
    },
    onBatchChangeModelClick() {
      this.selectType = 'forChangeModel';
      this.onSelectModelClick()
    },
    onCardSelectChange(card) {
      console.log(card.isChecked);
      if (card.isChecked) {
        if (this.withoutBatchOptionIds.length > 0) {
          let index = this.withoutBatchOptionIds.indexOf(card.id);
          if (index > -1) {
            this.withoutBatchOptionIds.splice(index, 1);
          }
        }

        if (!this.batchAllIsChecked) {
          this.batchOptionIds.push(card.id);
          if (this.batchOptionIds.length == this.cardListCount) {
            this.withoutBatchOptionIds = [];
          }
        }

        this.batchAllIsChecked = this.batchOptionIds.length == this.cardListCount;
      } else {
        if (this.batchOptionIds.length > 0) {
          let index = this.batchOptionIds.indexOf(card.id);
          if (index > -1) {
            this.batchOptionIds.splice(index, 1);
          }
        }

        if (this.batchAllIsChecked) {
          this.withoutBatchOptionIds.push(card.id);
          if (this.withoutBatchOptionIds.length == this.cardListCount) {
            this.batchAllIsChecked = false;
            this.batchOptionIds = [];
            this.withoutBatchOptionIds = [];
          }
        }
      }

      console.log("batchAllIsChecked", this.batchAllIsChecked);
      console.log("batchOptionIds", this.batchOptionIds);
      console.log("withoutBatchOptionIds", this.withoutBatchOptionIds);
    },
    onSelectAllChange(e) {
      console.log(this.batchAllIsChecked);
      this.batchOptionIds = [];
      this.withoutBatchOptionIds = [];
      if (this.batchAllIsChecked) {
        this.cardList.forEach((v, k) => {
          this.cardList[k].isChecked = true;
        })
      } else {
        this.cardList.forEach((v, k) => {
          this.cardList[k].isChecked = false;
        })
      }
    },
    onAkCoverClick(e) {
      console.log(e.target.style.color);
      if (e.target.style.color == 'rgb(0, 0, 0)') {
        e.target.style.color = '#F4EDB3';
      } else {
        e.target.style.color = '#000000';
      }
      console.log('>>>' + new Date().getTime());
    },
    revTxt(due) {
      if (due > 0) {
        return due + '天后复习';
      } else if (due < 0) {
        return '逾期' + Math.abs(due) + '天';
      } else {
        return '今日复习';
      }
    },
    onBatchEditClick() {
      if (this.cardListCount === 0)
        return;
      this.batchAllIsChecked = false;
      this.batchOptionIds = [];
      this.withoutBatchOptionIds = [];
      this.batchEditing = !this.batchEditing;
    },
    onBatchChangeFlagClick() {
      if (this.batchAllIsChecked) {
        if (this.withoutBatchOptionIds.length == this.cardListCount) {
          this.$message.info('请选择卡牌');
          return;
        }
      } else {
        if (this.batchOptionIds.length === 0) {
          this.$message.info('请选择卡牌');
          return;
        }
      }
      this.showFlagOptions = true;
    },
    onFlagClick(flags) {
      if (this.selectFlagCardIndex === -1) {
        let loading = this.$loading('处理中');
        request.request({
          url: '/space.card/batchFlagCard',
          method: 'post',
          data: {
            checkedAll: this.batchAllIsChecked ? 'yes' : 'no',
            batchOptionIds: JSON.stringify(this.batchOptionIds),
            withoutBatchOptionIds: JSON.stringify(this.withoutBatchOptionIds),
            searchForm: JSON.stringify(this.searchForm),
            cardListCount: this.cardListCount,
            flag: flags
          }
        }).then(() => {
          loading.close();
          this.showFlagOptions = false;
          this.$message.success('操作成功');
          this.searchForm.page = 1;
          this.getCardList();
        }).catch((e) => {
          loading.close();
          this.$message.error(e);
        });
      } else {
        let card = this.cardList[this.selectFlagCardIndex];
        let oldFlags = card.flags;
        card.flags = flags;
        request.request({url: '/space.card/setFlags', method: 'post', data: {cardId: card.id, flags: flags}})
          .then((data) => {
            this.showFlagOptions = false;
          }).catch((e) => {
          this.$message.error(e);
          this.showFlagOptions = false;
          card.flags = oldFlags;
          this.selectFlagCardIndex = -1;
        }).catch(() => {
          this.selectFlagCardIndex = -1;
        })
      }
    },
    onMarkedClick(card, type) {
      let oldTags = card.tags;
      if (type) {
        card.tags = ' marked ';
      } else {
        card.tags = '';
      }
      request.request({url: '/space.card/setMarked', method: 'post', data: {noteId: card.nid, type: type}})
        .then((data) => {

        }).catch((e) => {
        this.$message.error(e);
        card.tags = oldTags;
      })
    },
    formatSfld(content) {
      // console.log(content);
      if (content !== null) {
        content = content.replace(/<img[^>]+>/g, '[图片]').replace(/<.*?>/g, "");
        return content === '' ? '无内容' : content;
      }
    },
    onDeckClick(deck) {
      this.$router.push({path: '/space/deck/' + deck.id})
    },
    onDeleteCardClick(card) {
      this.$confirm('是否删除该卡牌?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            let loading = this.$loading();
            request.request({
              url: '/space.card/deleteCard',
              method: 'post',
              data: {id: card.id,}
            }).then((data) => {
              done()
              loading.close();
              this.$message.success('已删除');
              this.searchForm.page = 1;
              this.withoutBatchOptionIds = [];
              this.batchOptionIds = [];
              this.getCardList();
            }).catch((e) => {
              done()
              loading.close();
              this.$message.error(e);
            });
            done();
          } else {
            done();
          }
        }
      })
    },
    onImportClick() {
      this.$parent.onImportClick();
    },
    onSaveCardClick() {
      if (!this.$parent.loginUser.vip || (this.$parent.loginUser.vip && !this.$parent.loginUser.vip.vipAvailable)) {
        if (this.totalCard >= this.$parent.loginUser.nonmember.notesCount) {
          let count = this.$parent.loginUser.nonmember.notesCount;
          this.$alert('卡片数量超过' + count + '张了，开通会员不限卡片数量哦🌹', '提示', {
            confirmButtonText: '确定',
            callback: () => {
              this.$parent.onVipShowClick();
            }
          });
          return;
        }
      }
      if (!this.deckForEditCard) {
        this.$message.warning('请选择记忆库');
        return;
      }
      if (!this.selectModel) {
        this.$message.warning('请选择模板');
        return;
      }
      this.editCard.flds = [];
      this.tpl.forEach((v, k) => {
        //处理文本挖空
        v.value = v.value.replace(/on(click)="if\(this\.style\.color == '.*?'\)\{this\.style\.color = '.*?';\}else\{this\.style\.color = '.*?';\}"/gm, "");
        v.value = v.value.replace(new RegExp("class=\"ak-cover\"", "gm"),
          "class=\"ak-cover\" onclick=\"if(this.style.color == 'rgb(0, 0, 0)'){this.style.color = '#F4EDB3';}else{this.style.color = '#000000';}\"")

        //处理图片挖空
        if (v.value.indexOf('image-cover') > -1) {
          v.value = v.value.replace(new RegExp("<figure style='position:relative'", "gm"), "<figure");
          v.value = v.value.replace(new RegExp("<figure", "gm"), "<figure style='position:relative'");
          if (v.value.indexOf("if(!g.onclick)") === -1) {
            v.value += `<script>
if('undefined'===typeof hide_img_cover)hide_img_cover=$('.ck-back').length>0;
$('figure g').each(function(idx,g){
  if(hide_img_cover && $(g).find('text').length === 0)g.style.opacity=0.1;
  if(!g.onclick){g.onclick=function(){g.style.opacity=g.style.opacity==0.1?1:0.1}}
});
<\/script>`;
          }
        }

        this.editCard.flds.push(v.value)
      });
      let str = this.editCard.flds[0].replace(/\r/g, "");
      str = str.replace(/\n/g, "");
      // if (str === '') {
      //   this.$message.warning('第一个字段不能为空');
      //   return;
      // }

      let loading = this.$loading();
      let newCard = {};
      newCard.did = this.deckForEditCard.id;
      newCard.mid = this.selectModel.id;
      newCard.flds = JSON.stringify(this.editCard.flds);
      request.request({
        url: '/space.card/saveCard',
        method: 'post',
        data: newCard
      }).then(data => {
        loading.close();
        this.searchForm.page = 1;
        this.$message.success('卡牌添加成功');
        this.getCardList();
        this.getUserDecks();
        this.tpl.forEach((e, k) => {
          this.editors[k].setData('');
        });
      }).catch((e) => {
        loading.close();
        this.$message.error(e)
      });
    },
    onSaveSuccess(flds) {
      console.log(flds);
      if (this.editCard.nid) {
        this.$message.success('已保存');
        this.editCard.sfld = flds[0];
        this.editCard.flds = flds;
      } else {
        this.$message.success('添加成功!');
        this.searchForm.page = 1;
        this.getCardList();
        this.getUserDecks();
      }
    },
    onSelectDeckClick(type) {
      this.selectType = type;
      this.showSelectDeckDrawer = true;
    },
    onSelectModelClick() {
      if (this.selectType === 'forChangeModel') {
        var count = this.batchAllIsChecked ? this.cardListCount - this.withoutBatchOptionIds.length : this.batchOptionIds.length;
        if (count <= 0) {
          this.$message.warning('请选择卡片')

        } else {
          this.getModels(() => {
            this.showSelectModelDrawer = true;
          })
        }
        return;
      }

      this.selectType = '';
      if (this.deckForEditCard) {
        this.getModels(() => {
          this.showSelectModelDrawer = true;
        })
      } else {
        this.$message.warning('请先选择记忆库')
      }
    },
    onDeckSelect(deck) {
      if (this.selectType === 'forAddCard') {
        this.getModels(() => {
          request.request({
            url: '/space.index/getUsedMidByDeck',
            params: {did: deck.id}
          }).then((data) => {
            this.deckForEditCard = deck;
            this.$store.commit('setDeckData', this.deckForEditCard)
            this.showSelectDeckDrawer = false;
            if (data.models && data.models.length > 0) {
              this.onModelSelect(this.models[data.models[0].mid]);
            } else {
              this.onModelSelect(this.models[Object.keys(this.models)[0]]);
            }
          }).catch((e) => {
            this.$message.error(e);
          });
        });
      } else if (this.selectType === 'forSelectCard') {
        this.deckForSelectCard = deck;
        this.searchForm.did = deck.id;
        this.searchForm.page = 1;
        this.getCardList();
        setTimeout(() => {
          this.showSelectDeckDrawer = false;
        }, 150)
      } else if (this.selectType === 'forMove') {
        this.$confirm('已选卡牌将移动至记忆库：' + deck.simpleName + '，是否继续?', '移动卡牌', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'info',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              done();
              let loading = this.$loading('正在移动卡牌');
              request.request({
                url: '/space.card/batchMoveCard',
                method: 'post',
                data: {
                  checkedAll: this.batchAllIsChecked ? 'yes' : 'no',
                  batchOptionIds: JSON.stringify(this.batchOptionIds),
                  withoutBatchOptionIds: JSON.stringify(this.withoutBatchOptionIds),
                  searchForm: JSON.stringify(this.searchForm),
                  cardListCount: this.cardListCount,
                  did: deck.id,
                }
              }).then((data) => {
                loading.close();
                this.$message.success('操作成功');
                this.searchForm.page = 1;
                this.batchOptionIds = [];
                this.withoutBatchOptionIds = [];
                this.batchAllIsChecked = false;
                this.showSelectDeckDrawer = false;
                this.getCardList();
              }).catch((e) => {
                loading.close();
                this.$message.error(e);
              });
            } else {
              done();
            }
          }
        })
      }
    },
    onModelSelect(model) {
      if (this.selectType === 'forChangeModel') {
        let loading = this.$loading();
        this.$confirm('是否将选中的卡片更换模版？如所选卡片字段数与模版数不一致可能影响卡片的显示', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              request.request({
                url: '/space.card/batchChangeModel',
                method: 'post',
                data: {
                  checkedAll: this.batchAllIsChecked ? 'yes' : 'no',
                  batchOptionIds: JSON.stringify(this.batchOptionIds),
                  withoutBatchOptionIds: JSON.stringify(this.withoutBatchOptionIds),
                  searchForm: JSON.stringify(this.searchForm),
                  cardListCount: this.cardListCount,
                  modelId: model['id']
                }
              }).then((data) => {
                loading.close();
                this.showSelectModelDrawer = false;
                this.$message.success('操作成功');
                this.searchForm.page = 1;
                this.batchOptionIds = [];
                this.batchAllIsChecked = false;
                this.withoutBatchOptionIds = [];
                this.getCardList();
                this.selectType = '';
              }).catch((e) => {
                loading.close();
                this.$message.error(e);
              });
            }
            done();
          }
        });


      } else {
        this.selectModel = model;
        this.$store.commit('setModelsData', this.selectModel)
        this.tpl = [];
        model.flds.forEach((v, k) => {
          this.tpl.push({name: v.name, value: this.editors[k] ? this.editors[k].getData() : ''});
        });
        setTimeout(() => {
          this.showSelectModelDrawer = false;
          this.initEditor();
        }, 150)
      }
    },
    onModelDelete(model) {
      this.$confirm('是否删除模版：' + model.name + ' ？', '提示', {
        confirmButtonText: '确认删除',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            let loading = this.$loading();
            request.request({
              url: '/space.card/deleteModel',
              method: 'post',
              data: {modelId: model.id,}
            }).then((data) => {
              done();
              loading.close();
              let newModels = {};
              for (let key in this.models) {
                console.log(this.models[key]) // foo, bar
                if (key != model.id) {
                  newModels[key] = this.models[key];
                }
              }
              this.models = newModels;
              this.$message.success('操作成功');
            }).catch((e) => {
              done()
              loading.close();
              this.$message.error(e);
            });
          } else {
            done();
          }
        }
      })
    },
    onEditDeckClick(deck) {
      this.$prompt('', '重命名此记忆库', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: deck.name,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm' && instance.inputValue && instance.inputValue !== deck.name) {
            let loading = this.$loading();
            request.request({
              url: '/space.deck/editDeck',
              method: 'post',
              data: {
                did: deck.id,
                name: instance.inputValue
              }
            }).then((data) => {
              this.getUserDecks(() => {
                loading.close();
                done();
              });
            }).catch((e) => {
              loading.close();
              this.$message.error(e);
            })
          } else {
            done();
          }
        }
      });
    },
    onDeleteDeckClick(deck) {
      this.$confirm('是否删除该记忆库（内含卡牌也会被删除）?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            let loading = this.$loading();
            request.request({
              url: '/space.deck/deleteDeck',
              method: 'post',
              data: {did: deck.id,}
            }).then((data) => {
              this.getUserDecks(() => {
                loading.close();
                done();
              });
            }).catch((e) => {
              loading.close();
              this.$message.error(e);
            })
          } else {
            done();
          }
        }
      })
    },
    onExportDeckClick(deck) {
      if (!this.$parent.loginUser.vip || (this.$parent.loginUser.vip && !this.$parent.loginUser.vip.vipAvailable)) {
        let key = 'exportCard';
        let useCount = localStorage.getItem(key) || 0;
        let exportCardCount = this.$parent.loginUser.nonmember.exportCard;
        if (exportCardCount && exportCardCount >= useCount) {
          this.$confirm('导出卡牌资源为VIP超级学霸功能，开通会员后无限制使用🌹', '提示', {
            confirmButtonText: '先试用一下',
            cancelButtonText: '立即开通',
            showClose: false,
            closeOnClickModal: false,
          }).then(() => {

          }).catch(() => {
            this.$parent.onVipShowClick();
          });
        } else {
          this.$alert('导出卡牌资源功能试用次数已到达上限，需开通会员才可使用哦🌹', '提示', {
            confirmButtonText: '确定',
            callback: () => {
              this.$parent.onVipShowClick();
            }
          });
          return;
        }
      }
      this.exportDeck = deck
      this.isExportCard = true
    },
    closeExportCard() {
      this.isExportCard = false
    },
    getUserDecks(callback) {
      var that = this;
      request.request({url: '/space.index/progress'}).then((data) => {
        this.decks = data.decks;
        this.$store.commit('setDecksData', data.decks)
        this.newCard = data.newCard;
        this.reviewCard = data.reviewCard;
        this.learningCard = data.learningCard;
        this.relearningCard = data.relearningCard;
        this.totalCard = data.totalCard;
        setTimeout(function () {
          request.request({url: '/space.index/getProgressData'}).then((data) => {
            that.decks = data.decks;
            that.$store.commit('setDecksData', data.decks)
            that.newCard = data.newCard;
            that.reviewCard = data.reviewCard;
            that.learningCard = data.learningCard;
            that.relearningCard = data.relearningCard;
            that.totalCard = data.totalCard;
            callback && callback();
          }).catch((e) => {
            this.$message.error(e);
            callback && callback();
          });
        }, 1000);
        callback && callback();
      }).catch((e) => {
        this.$message.error(e);
        callback && callback();
      });
    },
    onSelectTypeClick(type) {
      if (type === this.searchForm.type) {
        return;
      }
      this.searchForm.page = 1;
      this.searchForm.type = type;
      this.getCardList();
    },
    onSelectSortTypeClick(sortType) {
      if (sortType === this.searchForm.sortType) {
        return;
      }
      this.searchForm.page = 1
      this.searchForm.sort_type = sortType;
      this.getCardList();
    },
    colorChange(color) {
      console.log(color);
      this.searchForm.flag = color;
      this.getCardList();
    },
    onSearchCard() {
      this.searchForm.page = 1;
      this.batchEditing = false;
      this.batchAllIsChecked = false;
      this.batchOptionIds = [];
      this.withoutBatchOptionIds = [];
      this.getCardList();
    },
    onSortClick() {
      this.searchForm.sort = this.searchForm.sort === 1 ? 2 : 1;
      localStorage.setItem('cardListSort', this.searchForm.sort);
      this.onSearchCard();
    },
    getCardList() {
      request.request({
        url: '/space.card/cardList', params: Object.assign(this.searchForm, {})
      }).then((data) => {
        this.loadMoreIng = false;
        data.list.forEach((v, k) => {
          data.list[k].isChecked = this.batchAllIsChecked;
          console.log(data.list[k].isChecked);
          if (this.searchForm.keyword !== '') {
            let text = "<span class='text-strong c-app-green'>" + this.searchForm.keyword + "</span>";
            data.list[k].fldsText = data.list[k].fldsText.replace(this.searchForm.keyword, text);
            data.list[k].sfld = data.list[k].sfld.replace(this.searchForm.keyword, text);
          }
        });
        if (this.searchForm.page === 1) {
          this.cardList = data.list;
          this.cardListCount = data.count;
        } else {
          this.cardList = this.cardList.concat(data.list);
        }
        this.noMore = data.list.length < this.searchForm.size;
      }).catch((e) => {
        this.loadMoreIng = false;
        this.$message.error(e);
      })
    },
    loadMoreCard() {
      if (this.loadMoreIng || this.noMore) {
        return;
      }
      this.loadMoreIng = true;
      this.searchForm.page++;
      this.getCardList();
    },
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop; //变量windowHeight是可视区的高度
      if (scrollTop + document.body.clientHeight > document.body.scrollHeight - 50) {
        this.loadMoreCard();
      }
    },
    onEditCardClick(e, card, index) {
      this.editCardOrPrevCardIndex = index
      if (JSON.stringify(this.decks) === "{}") {
        this.$message.warning('正在获取卡牌数据请稍候');
      } else {
        let findDeck = this.getDeckByDid(this.decks, card.did);
        if (findDeck == null) {
          this.$message.warning('数据有误');
          return;
        }
        this.getModels(() => {
          this.checkCardFlds(card, () => {
            if (this.isPreview) {

            } else {
              // 预览
              this.editCard = card;
              this.fullScreenEdit = true;
            }
          })
        });
      }
    },
    checkCardFlds(card, callback) {
      if (card.flds === false) {
        let loading = this.$loading({text: '获取卡牌内容'});
        request.request({
          url: '/space.card/getFlds',
          params: {noteId: card.nid}
        }).then(data => {
          loading.close();
          card.flds = data.flds;
          callback();
        }).catch(e => {
          loading.close();
          this.$message.error(e);
        })
      } else {
        callback();
      }
    },
    getDeckByDid(decks, did) {
      let deck = null;
      for (let k in decks) {
        if (decks[k].id == did) {
          return decks[k];
        }
        if (decks[k].decks) {
          deck = this.getDeckByDid(decks[k].decks, did);
          if (deck) {
            break;
          }
        }
      }
      return deck;
    },
    getModels(callback) {
      if (this.hasGetModels) {
        callback();
      } else {
        let loading = this.$loading();
        request.request({
          url: '/space.index/models',
        }).then(data => {
          this.hasGetModels = true;
          loading.close();
          if (data.models) {
            this.models = Object.assign(this.models, data.models);
          }
          this.$store.commit('setModelsData', this.models)
          console.log('>>>>>>this.models');
          // console.log(this.models);
          callback();
        }).catch(e => {
          loading.close();
          this.$message.error(e);
        })
      }
    },
    onEditorCloseClick() {
      try {
        if (e && e.type == 'delete') {
          let index = -1;
          this.cardList.forEach((v, k) => {
            if (v.id == e.id) {
              index = k;
            }
          });
          if (index > -1) {
            this.cardList.splice(index, 1);
            this.cardListCount--;
          }
        }
      } catch (e) {

      }
      this.editCard = {};
      this.fullScreenEdit = false;
    },
    initEditor() {
      this.currentEditorIndex = '';
      if (this.tpl.length > 0) {
        this.tpl.forEach((v, k) => {
          if (v.value) {//这里是修复之前编辑器把头尾去掉的bug
            let end = v.value.indexOf('</p>');
            if (end && v.value.indexOf('<p>') > end) {
              v.value = "<p>" + v.value;
            }

            let lastStar = v.value.lastIndexOf("<p>");
            if (lastStar && v.value.indexOf('</p>') < lastStar) {
              v.value += "</p>";
            }
          }

          let placeholder = "在此输入内容";
          if (this.selectModel && this.selectModel.id == 1001) {
            if (v.name === '问题') {
              placeholder = "色彩的三原色分别是什么？（示例）"
            }
            if (v.name === '选项') {
              placeholder = "每一行就是一个选项，选项之间用换行区分，无需输入前缀A、B、C系统会自动生成"
            }
            if (v.name === '答案') {
              placeholder = "假如第二个选项是正确答案输入数字2，若有多个选项则使用逗号分隔如1,2,3"
            }
          } else if (this.selectModel && this.selectModel.id == 1002) {
            placeholder = '填空卡制作挖空遮挡后，点击显示答案后自动取消全部遮挡';
          }

          if (this.editors[k]) {
            // this.editors[k].setData('');
            setTimeout(() => {
              document.getElementsByClassName('ck-placeholder')[k].setAttribute('data-placeholder', placeholder);
            }, 500);
          } else {
            AnkiEditor.create(document.querySelector('#edit-' + k), {
              placeholder: placeholder,
              simpleUpload: {
                uploadUrl: 'https://api.ankichinas.com/api/v1/napi/common/uploadImgByAnkimemoryWeb',
                headers: {Authorization: this.$parent.authToken}
              },
            }).then(editor => {
              //图标涂鸦编辑器
              editor.imageCover = (selectedElement) => {
                this.doImageCoverElement = selectedElement;
                this.doImageCoverEditor = editor;
                let imageElement = null;
                let hasCover = false;
                if (selectedElement.name === 'imageCover') {
                  hasCover = true;
                  imageElement = selectedElement.parent;
                } else {
                  imageElement = selectedElement;
                }

                this.showImageCoverEditor(imageElement.getAttribute('src'), hasCover ? selectedElement.getAttribute('draw') : '');
              };


              editor.model.document.on('change', () => {
                if (!this.deckForEditCard) {
                  if (document.getElementsByClassName('el-message').length === 0) {
                    // this.$message.warning('编辑前，请先选择记忆库和模板');
                  }
                } else if (!this.selectModel) {
                  if (document.getElementsByClassName('el-message').length === 0) {
                    // this.$message.warning('请选择模板');
                  }
                }

                let newHtml = editor.getData();
                if (newHtml.length > 6) {
                  if (newHtml.substr(0, 3).toLowerCase() === '<p>'
                    && newHtml.substr(newHtml.length - 4).toLowerCase() === '</p>'
                    && newHtml.indexOf('</p>') === newHtml.length - 4) {
                    newHtml = newHtml.substr(3, newHtml.length - 7)
                  }
                }
                console.log(newHtml);
                if (newHtml !== this.tpl[k].value) {
                  this.tpl[k].value = newHtml;
                  setTimeout(() => {
                    let test = document.getElementsByClassName('ak-cover');
                    for (let i = 0; i < test.length; i++) {
                      // test[i].addEventListener('click', this.onAkCoverClick)
                    }

                    console.log(this.data);
                  }, 10);
                }
              });
              editor.editing.view.document.on('focus', () => {
                this.currentEditorIndex = k;
              });
              editor.editing.view.document.on('blur', () => {
                console.log('The Document has blur!');
              });
              editor.setData(v.value);
              this.editors[k] = editor;
            }).catch(error => {
              console.error(error);
            });
          }
        })
      }
    },
    _mime(option, value) {
      var mimeTypes = navigator.mimeTypes;
      for (var mt in mimeTypes) {
        if (mimeTypes[mt][option] == value) {
          return true;
        }
      }
      return false;
    },
    handleKeyDown(e) {
      console.log(e.keyCode);
    },
    showImageCoverEditor(src, svg) {
      this.doImageCoverSrc = src;
      this.doImageCoverSvg = svg;
    },
    cancelImageEdit() {
      this.doImageCoverSrc = '';
    },
    finishImageCover(svg) {
      this.doImageCoverEditor.model.change(writer => {
        let imageElement;
        if (this.doImageCoverElement.name === 'imageCover') {
          imageElement = this.doImageCoverElement.parent;
          writer.remove(this.doImageCoverElement);
          if (svg === '') {
            return;
          }
        } else {
          imageElement = this.doImageCoverElement;
        }

        const newImageCoverElement = writer.createElement('imageCover');
        writer.setAttribute('draw', svg, newImageCoverElement);
        writer.append(newImageCoverElement, imageElement);
      });
      this.doImageCoverSrc = '';
    },
    getRecommendKeywordCard() {
      request.request({
        url: '/card/recommendKeywordCard',
        data: {},
        method: 'post',
      }).then((data) => {
        console.log(data);
        this.recommendCardList = data.list;
        if (this.recommendCardList.length == 0) {
          this.recommendText = '暂无推荐';
        }
      }).catch((e) => {
        this.$message.error(e);
      })
    },
    divSelElementOutOfViewport() {
      const devSel = document.getElementById('div_sel');
      const rect = devSel.getBoundingClientRect();
      let ret = (
        rect.top > window.innerHeight || // 元素底部完全在视口上方
        rect.bottom < 60 || // 元素顶部完全在视口下方60
        rect.left > window.innerWidth || // 元素右侧完全在视口左侧
        rect.right < 0 // 元素左侧完全在视口右侧
      );
      if (ret) {
        if (!this.divSelTop) {
          this.divSelTop = true;
          this.divDeskTop = true;
        }
      }
    },
    divContElementOutOfViewport() {
      const devCont = document.getElementById('div_cont');
      const rect = devCont.getBoundingClientRect();
      let ret = (
        rect.top < 60 // 元素底部完全在视口上方
      );
      if (!ret) {
        if (this.divSelTop) {
          this.divSelTop = false;
          this.divDeskTop = false;
        }
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.divSelElementOutOfViewport, true);
    window.addEventListener('scroll', this.divContElementOutOfViewport, true);

    if (this.$parent.loginUser) {
      this.getRecommendKeywordCard();
    }
    this.$parent.showImportOption = true;
    window.scrollTo(0, 0);
    this.$parent.showNavigateBar();
    this.tpl = [{name: '正面', value: ''}, {name: '背面', value: ''}];
    setTimeout(() => {
      this.initEditor();
    }, 100);
    this.getUserDecks();
    this.getCardList();
    window.addEventListener("scroll", this.handleScroll, true);
    if (navigator.userAgent.indexOf('QQB') > -1) {
      this.$alert('检测到当前为QQ浏览器，若卡牌无法编辑建议更换Edge或chrome浏览器');
    }
    if (this._mime("type", "application/vnd.chromium.remoting-viewer")) {
      this.$alert('检测到当前为360浏览器，若卡牌无法编辑建议更换Edge或chrome浏览器');
    }

    let disableNotice = localStorage.getItem("disableNotice");
    if (!disableNotice) {
      this.notice = "粘贴有样式的文本时默认会保留样式，只粘贴纯文本可使用组合键 shift+ctrl+v ";
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  created() {
    if (!this.$parent.loginUser) {
      this.$router.replace('/login');
    }
    for (let i = 0; i < 100; i++) {
      this.editors.push(null);
    }
    window.addEventListener("keydown", this.handleKeyDown, true);
  },
  watch: {
    fullScreenEdit() {
      if (this.fullScreenEdit) {
        document.body.style.cssText = 'overflow-y: hidden; height: 100%;';
      } else {
        document.body.style.cssText = 'overflow-y: auto; height: 100%;'
      }
    },
  },
  beforeUpdate() {
    this.$store.commit('setModelsData', this.selectModel)
    this.$store.commit('setDeckData', this.deckForEditCard)
  }
}
</script>

<style scoped>
.del {
  display: none;
}

.model-item:hover .del {
  display: block;
}

.model-item span:hover {
  color: #26b38c;
}

.deck-menu::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.deck-menu::-webkit-scrollbar {
  scrollbar-width: none; /* Firefox */
}

.deck-item:hover {
  background-color: #f4f4f4;
}

.tabs div {
  border-bottom: 2px solid transparent;
  color: #999999;
  cursor: pointer;
}

.tabs div.active {
  /*border-color: #222222;*/
  color: #222222;
  font-weight: 600;
}

.container /deep/ .el-input__inner {
  background-color: #f3f3f3;
  border: none;
  border-radius: 20px;
}


.sfld:hover {
  color: #28BEA0;
}

.cancelBtn >>> .el-button {
  background-color: #28BEA0;
  color: #fff;
  width: 200px;
  border-radius: 10px;
  margin: 20px 0;
}

.continueBtn >>> .el-button {
  background-color: #ff9041;
  color: #fff;
  width: 200px;
  border-radius: 10px;
  margin: 20px 0;
}

.myLoading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.myLoading .text {
  margin-top: 20px;
  font-size: 20px;
  color: #28BEA0;
}

.my-loading-icon {
  font-size: 80px;
  color: #28BEA0;
}

.el-alert--info.is-light {
  background-color: #f6f6f6;
  color: #aaaaaa;
}

.tooltip {

}

.tooltip .tooltiptext {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.ck p {
  margin: 3px 0px 3px 0px !important;
}
</style>
