<template>
  <el-dialog width="55%" top="8vh" :visible.sync="showVipDialog" :show-close="false" class="bdr-10">
    <div class="mg-l-20 mg-r-20">
      <div class="display-flex" slot="title">
        <img src="/static/image/vip_icon.png" class="w-34 h-36 mg-r-10"/>
        <div class="w-fill">
          <div v-if="isVip" class="justify-content-space-between align-items-center pd-b-5"
               style="border-bottom: rgba(255,255,255,.2) solid 1px;">
            <div class="c-0">
              <div class="fs-16">你成为超级学霸已{{ vipDay }}天</div>
              <div class="fs-14">{{ isForever ? '有效期至永久' : expireDay + '过期' }}</div>
            </div>
            <div class="justify-content-space-between">
              <div v-if="!isForever" @click="onPaySelectClick"
                   class="bdr-10 fs-14 pd-5 lh-24 w-66 text-align-center cursor-pointer mg-r-20"
                   style="background: linear-gradient(221deg, #FFF1E0 0%, #FFE5C6 100%, #FFC073 100%);">
                续费
              </div>
              <div class="fs-18 mg-t-10 el-icon-close cursor-pointer" @click="showVipDialog=false"></div>
            </div>
          </div>
          <div v-else-if="$parent.loginUser" class="justify-content-space-between pd-b-5"
               style="border-bottom: rgba(255,255,255,.2) solid 1px;">
            <div class="c-0">
              <div class="fs-16">未开通超级学霸</div>
              <div class="fs-16">{{ $parent.loginUser.phone }}</div>
            </div>
            <div class="justify-content-space-between">
              <div @click="onPaySelectClick"
                   class="bdr-16 pd-5 fs-14 lh-24 h-24 w-66 text-align-center cursor-pointer mg-r-20"
                   style="background: linear-gradient(221deg, #FFF1E0 0%, #FFE5C6 100%, #FFC073 100%);">
                立即开通
              </div>
              <div class="fs-18 mg-t-10 el-icon-close cursor-pointer" @click="showVipDialog=false"></div>
            </div>
          </div>
          <div v-else class="justify-content-space-between pd-b-25"
               style="border-bottom: rgba(255,255,255,.2) solid 1px;">
            <div class="c-f">
              <div class="fs-16">您尚未登录</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="h-fill">
      <div class="position-relative pd-0 mg-0">
        <div id="vipView" class="pd-b-10 position-relative" style="height: 560px; ">
          <div class="pd-15 fs-12">
            <div class="h-50 justify-content-center align-items-center"
                 style="background: linear-gradient(233deg, #FFECD6 0%, #FFD8A9 100%);border-radius: 10px 10px 0px 0px;">
              <img src="/static/image/vip_title1.png" class="w-130"/>
            </div>

            <div class="vip-privilege-container">
              <div class="vip-privilege-box">
                <div class="left flex-1 pd-b-24">
                  <div class="left-head h-88 lh-88 text-align-center">功能权益</div>
                  <div class="left-item active"> APP同步数据</div>
                  <div class="left-item"> 批量制卡</div>
                  <div class="left-item active"> 图片遮挡</div>
                  <div class="left-item"> 记忆库数量</div>
                  <div class="left-item active"> 卡片数量</div>
                  <!--                  <div class="left-item active"> 数据保存</div>-->
                </div>
                <div class="center left flex-1">
                  <div class="center-head left-head h-88 lh-88 text-align-center">
                    <div class="name">
                      超级学霸
                    </div>
                  </div>
                  <div class="left-item center-item active">
                    <div>是</div>
                  </div>
                  <div class="left-item center-item">
                    <div>是</div>
                  </div>
                  <div class="left-item center-item active">
                    <div>是</div>
                  </div>
                  <div class="left-item center-item">
                    <div>不限</div>
                  </div>
                  <div class="left-item center-item active">
                    <div>不限</div>
                  </div>
                  <!--                  <div class="left-item center-item active">
                                      <div>永久</div>
                                    </div>-->
                </div>
                <div class="left flex-1 pd-b-24">
                  <div class="free h-88 lh-88 text-align-center left-head">非超级学霸</div>
                  <div class="left-item center-item active">
                    <div class="c-0">否</div>
                  </div>
                  <div class="left-item center-item">
                    <div class="c-0">否</div>
                  </div>
                  <div class="left-item center-item active">
                    <div class="c-0">否</div>
                  </div>
                  <div class="left-item center-item">
                    <div class="c-0">{{ this.$parent.loginUser.nonmember.decksCount }}个</div>
                  </div>
                  <div class="left-item center-item active">
                    <div class="c-0">{{ this.$parent.loginUser.nonmember.notesCount }}</div>
                  </div>
                  <!--                  <div class="left-item center-item active">
                                      <div class="c-0">1年 (超过1年未登录将删除数据)</div>
                                    </div>-->
                </div>
              </div>
            </div>
            <div class="bg-f" style="border-radius: 0px 0px 10px 10px;" v-if="!isForever">
              <div v-if="pkg.length>1 && pkg[0].activityEndAt" class="fs-16 c-orange">{{
                  pkg[0].activityEndAt
                }}
              </div>
              <div class="display-flex text-align-center pd-t-10 pd-b-10">
                <div v-for="(item,index) in pkg"
                     class="flex-1 border-3 bdr-10 shadow-2 align-items-center justify-content-center h-110 mg-l-5 mg-r-5 position-relative cursor-pointer"
                     :class="index == selectPkg?'bdc-app-orange':'bdc-f'" @click="onPkgClick(index)">
                  <div class="column align-items-center">
                    <div class="c-orange fs-16 text-strong">￥ <span class="fs-22">{{ item.money }}</span></div>
                    <div v-if="item.originMoney" class=" text-delete c-9">
                      原价￥<span class="fs-12">{{ item.originMoney }}</span></div>
                    <div>{{ item.desc }}</div>
                  </div>
                  <div v-if="item.originMoney"
                       class="position-absolute t-0 fs-12 bg-app-orange c-f pd-2 pd-l-5 pd-r-5"
                       style="right: -3px;top:-3px;border-bottom-left-radius: 10px;border-top-right-radius: 10px"
                  >{{ item.desc2 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="position-relative w-fill bg-f" v-if="!isForever">
          <div class="justify-content-center">
            <el-radio-group v-model="payType" class="mg-t-20 display-inline-block">
              <el-radio label="alipay_web">
                <img src="/static/image/alipay.png" class="w-25" style="vertical-align: middle">
                支付宝
              </el-radio>
              <el-radio label="wechat_gzh">
                <img src="/static/image/wechatpay.png" class="w-30" style="vertical-align: middle">
                微信
              </el-radio>
            </el-radio-group>
          </div>
          <div @click="onPaySelectClick"
               class="mg-l-20 mg-r-20 mg-t-15 mg-b-6 pd-10 bg-app-orange c-f text-align-center lh-30 fs-20 bdr-10 cursor-pointer">
            {{ isVip ? '续费超级学霸' : '开通超级学霸' }}
            <div class="position-absolute flex-2 text-align-right fs-14 t-10 r-30">
              <span v-if="pkg.length > 0">￥{{ pkg[selectPkg].money }}{{ pkg[selectPkg].desc }}</span>
            </div>
          </div>
        </div>
      </div>
      <el-dialog title="支付提示" top="20vh" @close="payStatusClose" :modal="false" :visible.sync="showPayResult"
                 width="30%" class="bdr-10">
        <div class="mg-20">
          <div class="text-align-center">是否已支付完成？</div>
        </div>
        <div class="justify-content-center">
          <el-button @click="onPayCancel">未支付</el-button>
          <el-button type="primary" @click="onPayConfirm">已支付</el-button>
        </div>
      </el-dialog>
      <el-dialog title="打开微信扫码支付" top="20vh" :modal="false"
                 :visible.sync="showWxPayDialog"
                 width="30%" class="bdr-10">
        <div>
          <img class="w-fill" :src="qrImgUlr"/>
        </div>
      </el-dialog>
    </div>
  </el-dialog>
</template>

<script>
import request from '../libs/api.request'

export default {
  name: "vip",
  data() {
    return {
      showVipDialog: false,
      useSize: '-',
      totalSize: '-',
      pkg: [],
      isVip: false,
      copyPayUrl: '',
      vipDay: '',
      expireDay: '',
      selectPkg: 0,
      showPayResult: false,
      showWxPayDialog: false,
      ordering: false,
      remark: '',
      isForever: false,
      payType: 'alipay_web',
      qrImgUlr: '',
      interval: null,
    }
  },
  methods: {
    onChangeShow() {
      this.showVipDialog = true;
    },
    onPaySelectClick() {
      if (this.ordering) {
        return;
      }
      this.ordering = true;
      if (this.payType == 'wechat_gzh') {
        request.request({
          url: '/user/dataKeys',
          data: {
            id: this.pkg[this.selectPkg].id,
            returnUrl: '',
            payType: this.payType,
            remark: this.remark,
            platform: 2,//1app.2网页版
          },
          method: 'post'
        }).then((data) => {
          //弹出支付二维码
          let keys = data.keys;
          //let payUrl = ' http://192.168.90.114:8099/h5/wxWebPay?keys=' + keys;
          let payUrl = 'https://file.ankichinas.cn/h5/wxWebPay?keys=' + encodeURIComponent(keys);
          console.log(payUrl);
          this.qrImgUlr = 'https://file.ankichinas.cn/server/api/common/qrImg?content=' + payUrl;
          this.showWxPayDialog = true;
          this.ordering = false;
        }).catch((err) => {
          this.ordering = false;
          this.$message(err.message)
        })
      } else {
        request.request({
          url: '/user/vipOrder',
          data: {
            id: this.pkg[this.selectPkg].id,
            returnUrl: location.href,
            payType: this.payType,
            remark: this.remark,
            platform: 2,//1app.2网页版
          },
          method: 'post'
        }).then((data) => {
          this.ordering = false;
          this.copyPayUrl = data.copyPayUrl;
          this.showPayResult = true;
          window.open(data.payUrl);
        }).catch((err) => {
          this.ordering = false;
          this.$message(err.message)
        })
      }
      let expire = 0;
      let loginUser = this.$parent.loginUser;
      if (loginUser.vip) {
        expire = loginUser.vip.vipEndAt;
      }
      if (!this.interval) {
        this.interval = setInterval(() => {
          this.$parent.fetchServerUserInfo();
          if (this.$parent.loginUser.vip && this.$parent.loginUser.vip.vipEndAt > expire) {
            clearInterval(this.interval);
            this.onPayConfirm();
            this.$message({
              type: 'success',
              message: '支付成功',
              offset: 300,
              duration: 4000,
            });
          }
        }, 2000);
      }
    },
    onPayConfirm() {
      this.$parent.fetchServerUserInfo();
      this.showPayResult = false;
      this.showVipDialog = false;
      this.showWxPayDialog = false;
      this.ordering = false;
    },
    onPayCancel() {
      this.$parent.fetchServerUserInfo();
      this.showPayResult = false;
      this.showVipDialog = false;
      this.showWxPayDialog = false;
      this.ordering = false;
    },
    payStatusClose() {
      this.$parent.fetchServerUserInfo();
      this.showPayResult = false;
      this.showVipDialog = false;
      this.showWxPayDialog = false;
      this.ordering = false;
    },
    onPkgClick(index) {
      this.selectPkg = index;
    },
    getVipInfo() {
      request.request({
        url: 'user/vipInfo',
        params: {ios: 0}
      }).then(data => {
        this.pkg = data.pkg;
        //this.selectPkg = this.pkg.length - 1;
        this.isVip = data.isVip;
        this.totalSize = data.totalSize;
        this.useSize = data.useSize;
        this.vipDay = data.vipDay;
        this.expireDay = data.expireDay;
        this.isForever = data.isForever;
      }).catch((err) => {
        this.$message(err)
      });

    },
  },
  mounted() {
    if (this.$parent.loginUser) {
      this.getVipInfo();
    }
    if (this.$route.query['pc_web_pay_redirect'] === 'yes') {
      setTimeout(() => {
        this.showPayResult = true;
      }, 100)
    }
  }
}
</script>

<style scoped>
#alipay {
  border: 3px solid #FFFFFF;
  border-radius: 10px;
}

#alipay:hover {
  border: 3px solid #FF5622;
  border-radius: 10px;
}

.el-dialog__body {
  padding: 0px 20px 10px 20px !important;
}


.vip-privilege-container {
  max-width: 860px;
  border-radius: 24px;
  margin: 0 auto;
  background-color: #fff
}

.vip-privilege-container .head {
  position: relative;
  border-bottom: 1px solid #f7f5ee;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column
}

.vip-privilege-container .head .title {
  font-size: 28px;
  font-weight: 500;
  color: #131415;
  line-height: 40px;
  margin-top: 7px;
}

.vip-privilege-container .vip-privilege-box {
  display: flex;
  margin-bottom: 16px;
  position: relative;
  z-index: 9;
}

.vip-privilege-container .vip-privilege-box .left .left-head {
  font-size: 20px;
  font-weight: 500;
  color: #232222
}

.vip-privilege-container .vip-privilege-box .left .free {
  color: rgba(35, 34, 34, .6) !important
}

.vip-privilege-container .vip-privilege-box .left .left-item {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  color: #232222;
  height: 44px;
  line-height: 44px;
  text-align: center
}

.vip-privilege-container .vip-privilege-box .left .left-item.active {
  background-color: rgba(211, 248, 244, 0.2)
}

.vip-privilege-container .vip-privilege-box .left .center-item {
  color: #fa981f
}

/*.vip-privilege-container .vip-privilege-box .center {*/
/*box-shadow: 0 2 px 16 px 0 rgba(0, 0, 0, .11)*/
/*}*/

.vip-privilege-container .vip-privilege-box .center .center-head {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}

.vip-privilege-container .vip-privilege-box .center .center-head .name {
  font-size: 20px;
  font-weight: 500;
  color: #fa981f
}

#vipView::-webkit-scrollbar {
  width: 1px;
}

#vipView::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 5px;
}

#vipView::-webkit-scrollbar-track { /*滚动条底层颜色*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
  border-radius: 0;
  background: rgba(0, 0, 0, 0);
}

#vipView {
  scrollbar-width: 1px;
  scrollbar-base-color: green;
  scrollbar-track-color: red;
  scrollbar-arrow-color: blue;
  border-radius: 5px;
}

.el-radio__input.is-checked .el-radio__inner {
  border-color: #fa981f !important;
  background: #fa981f !important;
}

.el-radio__input.is-checked + .el-radio__label {
  color: #fa981f !important;
}

.el-radio__label {
  font-size: 16px !important;
  padding-left: 15px !important;
  color: #606266;
}

.el-dialog__header {
  padding: 0px 20px 20px 20px !important;
}
</style>
